import React, { createContext, useState, useContext, useEffect } from "react";
import LanguageModal from "components/LanguageModal";
import en from "../locales/en";
import pt from "../locales/pt";
import es from "../locales/es";

const LanguageContext = createContext();

const langsByCode = {
  en: 343,
  es: 383,
  pt: 375,
};

const langsAvailable = {
  en,
  pt,
  es,
};

export const useTranslation = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    try {
      return {
        language: en,
        langCodeTarget: langsByCode["en"],
        switchLanguage: () => {},
      };
    } catch (e) {
      console.log(e);
      return {};
    }
  }

  return context;
};

export const LanguageProvider = ({ children }) => {
  const [country, setCountry] = useState(() => {
    const lsCountry = localStorage.getItem("country");
    if (lsCountry) {
      try {
        return JSON.parse(lsCountry);
      } catch (e) {
        console.log(e);
      }
    }

    return {};
  });

  const [language, setLanguage] = useState(() => {
    const savedLang = localStorage.getItem("language");
    if (savedLang) {
      return savedLang === "pt" ? pt : savedLang === "es" ? es : en;
    }
    return en;
  });

  const switchLanguage = (lang) => {
    localStorage.setItem("language", lang);
    if (lang === "pt") {
      setLanguage(pt);
    } else if (lang === "es") {
      setLanguage(es);
    } else {
      setLanguage(en);
    }
  };

  const langCode = langsByCode[language.id];

  return (
    <LanguageContext.Provider
      value={{
        language,
        switchLanguage,
        country,
        setCountry,
        langsAvailable,
        langCode,
      }}
    >
      <LanguageModal />
      {children}
    </LanguageContext.Provider>
  );
};
