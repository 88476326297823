import { supabase } from "./auth";
import decks from "./decks";

const Integrations = {
  async getNotion(notionUrl) {
    try {
      const pageId = notionUrl?.split("/")?.pop();
      if (pageId) {
        const data = await fetch(
          `https://notion-api.splitbee.io/v1/table/${pageId}`
        ).then((r) => r.json());

        return data;
      }

      return null;
    } catch (e) {
      console.log(e);
    }
  },

  // async existFlashcard() {
  //   const { data } = await supabase.from('flashcards').select('*')
  //           .eq('deck_id', deckId)
  //           .eq('term', )
  //   return !!data
  // }

  async syncNotion(deckId, newFlashcards) {
    try {
      const { data: allFlashcard } = await supabase
        .from("flashcards")
        .select("*")
        .eq("deck_id", deckId)
        .order("updated_at", { ascending: false });

      const toRemoveItems = allFlashcard.filter((card) => {
        const hasItem = newFlashcards.find((item) => {
          return (
            card.term?.html === item.term &&
            card.definition?.html === item.definition
          );
        });
        return !hasItem;
      });

      //remove
      for (const flashcard of toRemoveItems) {
        await decks.deleteFlashcard(flashcard.id);
      }

      for (const newFlashcard of newFlashcards) {
        const hasExist = allFlashcard.find((card) => {
          return (
            card.term?.html === newFlashcard?.term &&
            card.definition?.html == newFlashcard?.definition
          );
        });

        if (hasExist) {
        } else {
          await decks.createFlashcard(deckId, {
            term: newFlashcard.term,
            definition: newFlashcard.definition,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
};

export default Integrations;
