import { supabase } from "services/auth";
import { navigate, Link } from "gatsby";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Text } from "@fluany/ds";
import Loader, { FullScreenLoader } from "components/Loader";
import "./UpgradeButton.scss";
import { useState, useEffect } from "react";
import { useUser } from "providers/user";
import config from "config";

function isRunningInReactNativeWebView() {
  return typeof window.ReactNativeWebView !== "undefined";
}

const UpgradeButton = ({
  className,
  children,
  text = "Subscribe",
  upgradeProps,
  ...props
}) => {
  const { user, onUpgrade } = useUser();
  const [isLoading, setIsLoading] = useState();

  return (
    <>
      <Button
        className={`upgrade-button ${className || ""}`}
        type={"gold"}
        weight={700}
        onClick={() => {
          setIsLoading(true);

          if (isRunningInReactNativeWebView()) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                paymentType: props.isYear ? "annual" : "monthly",
              })
            );
          } else {
            onUpgrade(upgradeProps);
          }
        }}
        {...props}
      >
        {text}
      </Button>
      {isLoading && !isRunningInReactNativeWebView() && <FullScreenLoader />}
    </>
  );
};

export default UpgradeButton;
