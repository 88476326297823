module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"fluany","description":"Force yourself to learn anything.","short_name":"fluany","start_url":"/?utm_source=pwa&utm_medium=pwa&utm_campaign=pwa","background_color":"#FF7272","theme_color":"#FFF","display":"standalone","icon":"src/images/pwa-icon.png","theme_color_in_head":false,"icons":[{"src":"/pwa-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/pwa-icon-512x512.png","sizes":"512x512","type":"image/png"}],"screenshots":[{"src":"/covers/screenshot1.jpg","type":"image/jpg","sizes":"1080x1920"},{"src":"/covers/screenshot2.jpg","type":"image/jpg","sizes":"1080x1920"},{"src":"/covers/screenshot3.jpg","type":"image/jpg","sizes":"1080x1920"},{"src":"/covers/screenshot4.jpg","type":"image/jpg","sizes":"1080x1920"}],"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e015911b2578c2143a3ba2c58dd75359"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_7ZpJa8D6cYnW2Ios0g63IBhZFUmXFQ3dHmJZoFdrk6a","head":true,"isEnabledDevMode":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
