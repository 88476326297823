const appName = process.env.GATSBY_APP_NAME;
const env = process.env.NODE_ENV;

const config = {
  appName,
  env,
  isMed: appName === "med",
  isDev: env === "development",
};

export default config;
