import { useEffect, useState } from "react";

const isClient = () =>
  !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );

const useMediaQuery = (size) => {
  const [matches, setMatches] = useState(false);

  const sizes = {
    mobile: "(max-width: 767px)",
    tablet: "(max-width: 1110px)",
    desktop: "(min-width: 1110px)",
  };

  const query = sizes[size] || `(max-width: ${size}px)`;

  useEffect(() => {
    if (isClient()) {
      const onResize = () => setMatches(window.matchMedia(query).matches);
      setMatches(window.matchMedia(query).matches);
      window.addEventListener("resize", onResize);
      return () => {
        window.removeEventListener("resize", onResize);
      };
    }
  }, []);

  return matches;
};

export default useMediaQuery;
