import { createContext, useContext, useEffect, useState } from "react";
import services from "services";
import { navigate } from "gatsby";
// import CreateModal from "../outpages/MyDecks/components/CreateModal";
import { useUser } from "./user";
import axios from "axios";
import { supabase } from "services/auth";

export const DeckContext = createContext({});

export const DeckProvider = ({ children }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [myDecks, setMyDecks] = useState([]);
  const [isMyDecksLoading, setIsMyDecksLoading] = useState(true);
  const [myDecksInProgress, setMyDecksInProgress] = useState([]);
  const [isMyDecksInProgressLoading, setIsMyDecksInProgressLoading] =
    useState(false);
  const [myDecksLiked, setMyDecksLiked] = useState([]);
  const [categories, setCategories] = useState({});
  const [myCourses, setMyCourses] = useState([]);

  const { user } = useUser();

  const loadCategories = async () => {
    const list = await services.decks.getCategories();
    setCategories(list);
  };

  const onCreateDeck = async (newDeck) => {
    setIsCreateLoading(true);
    const newDeckApi = await services.decks.create(user, newDeck);

    setMyDecks([newDeckApi, ...myDecks]);
    navigate(`/${user?.username}/decks/${newDeckApi.id}`);

    setIsCreateLoading(false);
    setIsOpen(false);
  };

  const deleteDeck = async (deckId) => {
    const isDelete = confirm(
      "Are you sure you want to delete this flashcard ?"
    );

    if (!isDelete) {
      return;
    }

    await services.decks.delete(deckId);
    const newDecks = myDecks.filter((deck) => deck.id !== parseInt(deckId));
    setMyDecks([...newDecks]);
  };

  const loadMyDecks = async () => {
    setIsMyDecksLoading(true);
    const resultDecks = await services.decks.getMyDecks(user?.id);
    setMyDecks([...resultDecks]);
    setIsMyDecksLoading(false);
  };

  const loadMyDecksInProgress = async () => {
    setIsMyDecksInProgressLoading(true);
    const resultDecks = await services.decks.getMyDecksInProgress(user?.id);
    setMyDecksInProgress([...resultDecks]);
    setIsMyDecksInProgressLoading(false);
  };

  const loadDecksLiked = async () => {
    const result = await services.decks.getLovedByUsername(user?.username);
    setMyDecksLiked([...result]);
  };

  useEffect(() => {
    if (user?.id) {
      loadMyDecks();
      loadMyDecksInProgress();
    }
  }, [user?.id]);

  useEffect(() => {
    loadCategories();
  }, []);

  return (
    <DeckContext.Provider
      value={{
        myDecks: myDecks.filter((deck) => deck.ownership),
        myDecksDownloads: myDecks.filter((deck) => !deck.ownership),
        myDecksInProgress,
        isMyDecksLoading,
        isMyDecksInProgressLoading,
        loadMyDecksInProgress,
        openCreateDeck: () => setIsOpen(true),
        deleteDeck,
        myDecksLiked,
        myCourses,
        categories,
        loadMyDecks,
        addInMyDeck: (newDeck) => setMyDecks([newDeck, ...myDecks]),
      }}
    >
      {children}
    </DeckContext.Provider>
  );
};

export const useDeck = () => {
  const context = useContext(DeckContext);
  return context;
};
