import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

import { Text, Button, Modal } from "@fluany/ds";
import { ReactComponent as ExploreIcon } from "./explore.svg";
import { FullScreenLoader } from "components/Loader";
import { FiExternalLink } from "react-icons/fi";
import {
  MdInvertColors,
  MdVolumeUp,
  MdQueryStats,
  MdStar,
  MdCheck,
} from "react-icons/md";
import "swiper/css";
import "swiper/css/pagination";

import "./Welcome.scss";

const items = [
  {
    title: (
      <Text as={"h3"} className={"welcome__item-title"}>
        <a
          href="https://app.fluany.com/?utm_source=platform&utm_campaign=welcome"
          target="_blank"
        >
          Discover our flashcards <FiExternalLink size={18} />
        </a>
      </Text>
    ),
    description: (
      <Text className="welcome__item-description">
        Start learning your way! Browse our flashcard decks, pick your
        favorites, and sharpen your skills with each flip.
      </Text>
    ),
    img: "/gifs/preview-store.gif",
  },
  {
    title: (
      <Text as={"h3"} className={"welcome__item-title"}>
        <a
          href="https://app.fluany.com/deck/create?utm_source=platform&utm_campaign=welcome"
          target="_blank"
        >
          Create your own flashcards <FiExternalLink size={18} />
        </a>
      </Text>
    ),
    description: (
      <Text className="welcome__item-description">
        Create your own flashcard decks! Personalize your study materials and
        make learning fun and engaging.{" "}
        <strong>Share your decks with friends</strong> and the community, and
        let's grow together!
      </Text>
    ),
    img: "/gifs/create-demo.gif",
  },
  {
    title: (
      <Text as={"h3"} className={"welcome__item-title"}>
        <a
          href="https://chromewebstore.google.com/detail/fluany/gijlnmefafhloacckomgabbndcepdnce"
          target="_blank"
        >
          Install Our Chrome Extension
          <FiExternalLink size={18} />
        </a>
      </Text>
    ),
    description: (
      <Text className="welcome__item-description">
        After finding your favorite flashcard decks, install our Chrome
        extension to get{" "}
        <strong>periodic flashcard pop-ups as you browse</strong>. It's the
        perfect way to keep your skills sharp and your knowledge growing.
      </Text>
    ),
    img: "/gifs/fluany-extension-demo.gif",
  },
  {
    title: (
      <Text as={"h3"} className={"welcome__item-title"}>
        Continue Your Learning Journey
      </Text>
    ),
    description: (
      <Text className="welcome__item-description">
        Regular study sessions are key to mastering any subject. Stay{" "}
        <strong>consistent</strong>, stay <strong> curious</strong>, and
        remember that <strong>persistence is your path</strong> to success.
        Happy studying and here's to your learning journey!
      </Text>
    ),
    img: (
      <img
        className="welcome__item-illustration"
        src={"/illustrations/girl-sit.svg"}
      />
    ),
  },
];

const Welcome = ({ isOpen, setIsOpen, message, title }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [active, setActive] = useState(items[0]);
  const isLastStep = swiper?.activeIndex === items.length - 1;

  return (
    <div className="welcome">
      <Modal id={"welcome"} width={580} isOpen={isOpen} setIsOpen={setIsOpen}>
        <Text as={"h2"} size={"sm"} className="welcome__title">
          {title || "Welcome to fluany 👋"}
        </Text>

        <Text className="welcome__description" size={"xs"}>
          Ready to boost your learning? Here’s how to start:
        </Text>

        <Swiper
          onSwiper={setSwiper}
          autoHeight={true}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          modules={[Pagination, Navigation]}
          onSlideChange={(swiper) => {
            setActive(items[swiper.activeIndex]);
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
          }}
        >
          {items.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className={`welcome__item`}>
                  <Text size={"xs"} className="welcome__item-step">
                    STEP {index + 1}
                  </Text>
                  {item.title}
                  {item.description}

                  {typeof item.img === "string" ? (
                    <img className="welcome__item-img" src={item.img} />
                  ) : (
                    item.img
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="welcome__sender">
          {!isLastStep ? (
            <>
              {swiper?.activeIndex > 0 && (
                <Button
                  type="default"
                  fluid
                  onClick={() => swiper.slideTo(swiper.activeIndex - 1)}
                >
                  Back
                </Button>
              )}
              <Button
                type="secondary"
                fluid
                onClick={() => {
                  swiper.slideTo(swiper.activeIndex + 1);
                }}
              >
                Next
              </Button>
            </>
          ) : (
            <Button fluid type={"primary"} onClick={() => setIsOpen(false)}>
              Start learning :)
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Welcome;
