import { supabase } from "./auth";
import utils from "../utils";
import moment from "moment";
import config from "config";
import { categories } from "../constants";

const getPagination = (page, size) => {
  const limit = size ? +size : 3;
  const from = page ? page * limit : 0;
  const to = page ? from + size : size;

  return { from, to };
};

const convertBlocksToHtml = (blocks) => {
  return blocks
    .map((item) => {
      if (item.type === "simpleImage") {
        return `<p><img src="${item.data.url}"/></p>`;
      }

      if (item.type === "paragraph") {
        return `<p>${item.data.text}</p>`;
      }
      return item;
    })
    .join("\n");
};

const decks = {
  async getAllByTag(tag, limit = 6) {
    if (tag === "recents") {
      try {
        const { data } = await supabase
          .from("decks")
          .select("*, profiles!inner(*)")
          .order("created_at", { ascending: false })
          .limit(limit);

        if (!data || data?.length === 0) {
          return [];
        }

        const decks = data.map((item) => ({
          ...item,
          ...item.decks,
          author: item?.profiles,
        }));

        return decks;
      } catch (e) {
        console.log(e);
      }
    }

    if (tag === "popular") {
      try {
        const { data, error } = await supabase
          .rpc("get_popular_decks")
          .select("*, profiles!inner(*)")
          .limit(limit);

        if (!data || data?.length === 0) {
          return [];
        }

        return data.map((item) => ({
          ...item,
          author: item.profiles,
        }));
      } catch (e) {
        console.log("[popular] error", e);
      }
    }

    return [];
  },

  async getAllBySourceAndTarget({ sourceId, targetId }) {
    let query = supabase
      .rpc("get_popular_decks")
      .select("*, profiles!inner(*)");
    let queryUnliked = supabase.from("decks").select("*, profiles!inner(*)");

    const mapCategoriesCode = {
      343: categories.english,
      308: categories.medicine,
      311: categories.entranceExam,
    };

    const categoryList = mapCategoriesCode[sourceId];
    if (sourceId && categoryList) {
      query = query.in("source", categoryList);
      queryUnliked = queryUnliked.in("source", categoryList);
    } else if (sourceId) {
      query = query.eq("source", sourceId);
      queryUnliked = queryUnliked.eq("source", sourceId);
    }

    if (targetId) {
      query = query.in("target", [targetId, 343]);
      queryUnliked = queryUnliked.eq("target", targetId);
    }

    const { data } = await query;

    const mapped =
      data?.map((item) => ({
        ...item,
        id: item.deck_id,
        author: item?.profiles,
      })) || [];

    mapped.forEach((item) => {
      queryUnliked = queryUnliked.neq("id", item.id);
    });

    try {
      const { data: decksUnliked = [] } = await queryUnliked;
      const decksUnlikedMapped = decksUnliked.map((item) => ({
        ...item,
        author: item?.profiles,
      }));

      return [...mapped, ...decksUnlikedMapped];
    } catch (e) {
      console.log(e);
    }
  },
  async getCountLikesByDeckId(deckId) {
    try {
      const { count } = await supabase
        .from("profiles_decks_likes")
        .select("*", { count: "exact" })
        .eq("deck_id", deckId);

      return count;
    } catch (e) {
      console.log(e);
    }
  },

  async checkIfHasLikeDeckIdByProfileId({ profileId, deckId }) {
    try {
      const { data } = await supabase
        .from("profiles_decks_likes")
        .select("*")
        .eq("deck_id", deckId)
        .eq("profile_id", profileId);

      return !!data?.length;
    } catch (e) {
      console.log(e);
    }
  },

  async likeToggleByDeckId({ profileId, deckId, hasMyNewLike }) {
    try {
      if (hasMyNewLike) {
        await supabase
          .from("profiles_decks_likes")
          .insert({
            profile_id: profileId,
            deck_id: deckId,
          })
          .eq("deck_id", deckId)
          .eq("profile_id", profileId);
      } else {
        await supabase
          .from("profiles_decks_likes")
          .delete()
          .eq("deck_id", deckId)
          .eq("profile_id", profileId);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async getMyDecks(userId) {
    try {
      const { data } = await supabase
        .from("profiles_decks")
        .select(
          `
          ownership,
          decks:deck_id (
            profiles: author(username),
            background,
             created_at,
             description,
             id,
             img,
             name
          )
`
        )
        .order("created_at", { ascending: false })
        .eq("profile_id", userId);

      const myDecks =
        data?.map((item) => ({
          ownership: item.ownership,
          ...item.decks,
          author: item?.decks?.profiles,
        })) || [];

      return myDecks;
    } catch (e) {
      console.log(e);
    }
  },

  async getMyDecksInProgress(userId) {
    try {
      const { data: progressDecks = [] } = await supabase
        .rpc("get_user_progress_decks", { user_id: userId })
        .select("*");

      const result = progressDecks.map((item) => ({
        id: item.deck_id,
        name: item.deck_name,
        img: item.deck_img,
        background: item.deck_background,
        author: {
          username: item?.author_username,
          full_name: item?.author_name,
        },
      }));

      return result;
    } catch (e) {
      console.log(e);
    }
  },

  async getLovedByUsername(username) {
    try {
      const { data } = await supabase
        .from("profiles_decks_likes")
        .select("*, profiles!inner(username), decks!inner(*)")
        .eq("profiles.username", username);

      return (
        data?.map((deck) => ({
          ...deck,
          ...deck?.decks,
          author: deck?.profiles,
        })) || []
      );
    } catch (e) {
      console.log(e);
    }
  },

  async getByUsername(username) {
    try {
      const { data } = await supabase
        .from("decks")
        .select("*, profiles!inner(*)")
        .eq("profiles.username", username);

      return (
        data?.map((deck) => ({
          ...deck,
          author: deck?.profiles,
        })) || []
      );
    } catch (e) {
      console.log(e);
    }
  },

  async getById(id) {
    try {
      const { data } = await supabase
        .from("decks")
        .select(
          `
profiles: author(id, username, full_name, avatar_url, email),
background,
description,
id,
img,
integration_store,
source(slug, name, group, language_code, id, img),
name,
target(slug, name, group, language_code, id, img),
is_require_pro
`
        )
        .eq("id", id)
        .single();

      return {
        ...data,
        author: data?.profiles,
      };
    } catch (e) {
      console.log(e);
    }
  },

  async createFlashcard(deckId, flashcard) {
    if (!deckId) {
      return;
    }

    try {
      const { data } = await supabase
        .from("flashcards")
        .insert({
          deck_id: deckId,
          term: { html: flashcard.term },
          definition: { html: flashcard.definition },
        })
        .select("id");

      return {
        ...flashcard,
        id: data?.[0]?.id,
        deck_id: deckId,
      };
    } catch (e) {
      console.log(e);
    }
  },

  async getIfHasMyLikeInDeckById({ deckId, profileId }) {
    try {
      const { data } = await supabase
        .from("profiles_decks_likes")
        .select("*")
        .match({ profile_id: profileId, deck_id: deckId });

      return !!data?.length;
    } catch (e) {
      console.log(e);
    }
  },

  async getFlashcardProgressPercent({ deckId, profileId }) {
    try {
      const { data } = await supabase
        .rpc("get_flashcard_count", { deckid: deckId, profileid: profileId })
        .select("*");

      const status = data?.[0];

      if (!status) {
        return 0;
      }

      const total = status?.total;
      const progress = status?.progress;

      const percent = (progress * 100) / total;

      return percent;
    } catch (e) {
      console.log(e);
    }
  },

  async updateFlashcard(flashcardId, newFlashcard) {
    try {
      await supabase
        .from("flashcards")
        .update({
          term: {
            html: newFlashcard.term,
          },

          definition: {
            html: newFlashcard.definition,
          },
        })
        .eq("id", flashcardId);
    } catch (e) {
      console.log(e);
    }
  },

  async deleteFlashcard(flashcardId) {
    try {
      await supabase.from("flashcards").delete().eq("id", flashcardId);
    } catch (e) {
      console.log(e);
    }
  },

  async getFlashcardsCountByDeckId(deckId) {
    try {
      const { count } = await supabase
        .from("flashcards")
        .select("*", { count: "exact" })
        .eq("deck_id", deckId)
        .order("updated_at", { ascending: false })
        .limit(1);

      return count || 0;
    } catch (e) {
      console.log(e);
    }
  },

  async getFlashcardToPlay(deckId, profileId) {
    try {
      const { data } = await supabase
        .rpc("get_random_flashcard_to_play", {
          profileid: profileId,
          deckid: deckId,
        })
        .select("*");

      const flashcard = data?.[0];

      if (flashcard) {
        return {
          ...flashcard,
          term: flashcard?.term?.blocks
            ? convertBlocksToHtml(flashcard?.term?.blocks)
            : flashcard?.term?.html,

          definition: flashcard.definition?.blocks
            ? convertBlocksToHtml(flashcard?.definition?.blocks)
            : flashcard?.definition?.html,
        };
      }

      return null;
    } catch (e) {
      console.log(e);
    }
  },

  async getFlashcardsByDeckId(deckId, page = 0) {
    const { from, to } =
      page === 0 ? { from: 0, to: 9 } : getPagination(page, 10);

    try {
      const { data } = await supabase
        .from("flashcards")
        .select("*")
        .eq("deck_id", deckId)
        .order("updated_at", { ascending: false })
        .range(from, to);

      const formatted = data?.map((item) => ({
        ...item,
        term: item.term?.blocks
          ? convertBlocksToHtml(item.term?.blocks)
          : item?.term?.html,
        definition: item.definition?.blocks
          ? convertBlocksToHtml(item.definition?.blocks)
          : item?.definition?.html,
      }));

      return formatted || [];
    } catch (e) {
      console.log(e);
      return [];
    }
  },

  async setKnowFlashcard(deckId, flashcard, profileId) {
    const knowData = {
      flashcard_id: flashcard.id,
      profile_id: profileId,
      deck_id: deckId,
    };

    try {
      await supabase.from("flashcards_progress").insert(knowData);
      await supabase.from("flashcards_know_history").insert({
        profile_id: profileId,
        deck_id: deckId,
        term: flashcard.term,
        definition: flashcard.definition,
      });
    } catch (e) {
      console.log(e);
    }
  },

  async addDeckForOtherUser(deckId, profileId) {
    try {
      await supabase.from("profiles_decks").insert({
        profile_id: profileId,
        deck_id: deckId,
        ownership: false,
      });
    } catch (e) {
      console.log(e);
    }
  },

  async getKnowHistory(profileId) {
    try {
      const { data } = await supabase
        .from("flashcards_know_history")
        .select("created_at")
        .eq("profile_id", profileId)
        .order("created_at");

      if (!data) {
        return {
          totalByDate: {},
        };
      }

      const totalFlashcards = data?.length;

      const totalByDate = data?.reduce((acc, item) => {
        const formatted = new Date(item.created_at).toISOString().slice(0, 10);
        if (acc[formatted]) {
          acc[formatted] = acc[formatted] + 1;
        } else {
          acc[formatted] = 1;
        }

        return acc;
      }, {});

      const now = moment();
      let streaks = 0;
      let lastStreak = null;
      const today = moment().format("YYYY-MM-DD");
      const dates = Object.keys(totalByDate);
      dates.forEach((date, index) => {
        if (index === 0) {
          return;
        }

        const currDate = moment(date);
        const prevDate = moment(dates[index - 1]);
        const duration = moment.duration(currDate.diff(prevDate));
        const isNextDay = duration.asDays() === 1;
        if (date === today) {
        } else if (isNextDay) {
          streaks++;
          lastStreak = currDate;
        } else if (date !== moment().format("YYYY-MM-DD")) {
          streaks = 0;
        }
      });

      const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
      const lastSteakFormatted = lastStreak?.format("YYYY-MM-DD");
      const isValidSteak =
        lastSteakFormatted === yesterday ||
        lastSteakFormatted === today ||
        dates[dates?.length - 1] === today; //last date is today

      if (!totalFlashcards) {
        return {
          totalFlashcards: 0,
          totalByDate: {},
        };
      }

      return {
        totalFlashcards,
        totalByDate,
        totalStreaks: isValidSteak ? streaks + 1 : 0,
      };
    } catch (e) {
      console.log(e);
    }
  },

  async getKnowFlashcardsCountByDeckId(deckId, profileId) {
    try {
      const { data, count } = await supabase
        .from("flashcards_progress")
        .select("*, flashcards!inner(*)", { count: "exact" })
        .eq("flashcards.deck_id", deckId)
        .eq("profile_id", profileId)
        .limit(1);

      return count;
    } catch (e) {
      console.log(e);
    }
  },

  async getKnowFlashcardsByDeckId(deckId, profileId) {
    try {
      const { data } = await supabase
        .from("flashcards_progress")
        .select("*, flashcards!inner(*)")
        .eq("flashcards.deck_id", deckId)
        .eq("profile_id", profileId);

      return (
        data?.map((item) => ({
          ...item,
          id: item.flashcard_id,
        })) || []
      );
    } catch (e) {
      console.log(e);
    }
  },

  async removeProgressById(deckId) {
    try {
      await supabase.from("flashcards_progress").delete().eq("deck_id", deckId);
    } catch (e) {
      console.log(e);
    }
  },

  async create(user, newDeck) {
    const {
      background,
      img,
      description,
      name,
      source,
      target,
      integration_store,
    } = newDeck;
    const newIntegration = integration_store?.url
      ? {
          name: integration_store?.name,
          url: integration_store?.url,
        }
      : null;

    try {
      const { data: deckInserted } = await supabase
        .from("decks")
        .insert({
          author: user.id,
          name,
          description,
          background,
          img,
          source,
          target,
          integration_store: newIntegration,
        })
        .select("*")
        .single();

      await supabase.from("profiles_decks").insert({
        profile_id: user.id,
        deck_id: deckInserted.id,
      });

      return {
        ...newDeck,
        id: deckInserted.id,
      };
    } catch (e) {
      console.log(e);
    }
  },

  async delete(deckId) {
    try {
      await supabase.from("profiles_decks").delete().eq("deck_id", deckId);
      let { error: errorLikes } = await supabase
        .from("profiles_decks_likes")
        .delete()
        .eq("deck_id", deckId);

      if (errorLikes)
        return console.error("Erro em profiles_decks_likes:", errorLikes);

      // Remover entradas em 'courses_decks'
      let { error: errorCourses } = await supabase
        .from("courses_decks")
        .delete()
        .eq("deck_id", deckId);
      if (errorCourses)
        return console.error("Erro em courses_decks:", errorCourses);

      // Remover de 'review_history'
      let { error: errorReviewHistory } = await supabase
        .from("review_history")
        .delete()
        .eq("deck_id", deckId);

      if (errorReviewHistory)
        return console.error("Erro em review_history:", errorReviewHistory);

      // Remover de 'spaced_repetition_status'
      let { error: errorSpacedRepetition } = await supabase
        .from("spaced_repetition_status")
        .delete()
        .eq("deck_id", deckId);

      if (errorSpacedRepetition)
        return console.error(
          "Erro em spaced_repetition_status:",
          errorSpacedRepetition
        );

      // Remover os flashcards
      let { error: errorDeleteFlashcards } = await supabase
        .from("flashcards")
        .delete()
        .eq("deck_id", deckId);

      if (errorDeleteFlashcards)
        return console.error(
          "Erro ao deletar flashcards:",
          errorDeleteFlashcards
        );

      // Finalmente, deletar o deck
      let { error: errorDeleteDeck } = await supabase
        .from("decks")
        .delete()
        .eq("id", deckId);

      if (errorDeleteDeck)
        return console.error("Erro ao deletar deck:", errorDeleteDeck);
    } catch (e) {
      console.log(e);
    }
  },

  async update(newDeck, profileId) {
    const {
      background,
      img,
      description,
      name,
      source,
      target,
      integration_store,
    } = newDeck;

    const newIntegration = integration_store?.url
      ? {
          name: integration_store?.name,
          url: integration_store?.url,
        }
      : null;

    try {
      await supabase
        .from("decks")
        .update({
          name,
          description,
          background,
          img,
          source,
          target,
          integration_store: newIntegration,
        })
        .eq("id", newDeck.id)
        .select("*")
        .single();

      return {
        ...newDeck,
      };
    } catch (e) {
      console.log(e);
    }
  },

  async getCategories() {
    try {
      const { data } = await supabase
        .from("categories")
        .select("*")
        .order("name");

      const grouped = utils.groupBy(data || [], "group");
      return grouped || {};
    } catch (e) {
      console.log(e);
    }
  },

  async getUserIdByUsername(username) {
    try {
      const { data } = await supabase
        .from("profiles")
        .select("id")
        .eq("username", username)
        .single();

      return data?.id;
    } catch (e) {
      console.log(e);
    }
  },

  async fetchFlashcards({ deckId, userId }) {
    const currentDate = new Date();

    try {
      // Buscar cartões com status de repetição
      const { data: allStatusCards, error: allStatusCardsError } =
        await supabase
          .from("spaced_repetition_status")
          .select(
            `
        *,
        flashcard:flashcard_id (
          id,
          term,
          definition
        )
      `
          )
          .eq("profile_id", userId)
          .not("is_reseted", "is", true)
          .eq("deck_id", deckId);

      if (allStatusCardsError) throw allStatusCardsError;

      // Filtrar os cartões com base no intervalo e na data de revisão
      const toReview = allStatusCards.filter((card) => {
        const nextReviewDate = new Date(card.next_review_date);
        return nextReviewDate <= currentDate;
      });

      // Separar os cartões que estão em fase de aprendizado (intervalo em minutos)
      const learning = toReview.filter((card) => {
        const intervalMinutes = card.interval;
        const nextReviewDate = new Date(card.next_review_date);
        return intervalMinutes <= 1440 && nextReviewDate <= currentDate; // 1440 minutos em um dia
      });

      // Excluir os cartões de aprendizado da lista 'toReview'
      const toReviewFiltered = toReview.filter(
        (tr) => !learning.find((l) => l.flashcard_id === tr.flashcard_id)
      );

      // Obter os IDs dos cartões que já têm um status
      const statusCardIds = allStatusCards.map((card) => card.flashcard_id);

      // Buscar cartões novos
      const newCardsLimit = 10;
      const { data: newCards, error: newCardsError } = await supabase
        .from("flashcards")
        .select("*")
        .eq("deck_id", deckId)
        .not("id", "in", `(${statusCardIds.join(",")})`)
        .limit(newCardsLimit);

      if (newCardsError) throw newCardsError;

      // Formatando os resultados
      const formatCard = (item) => ({
        ...item,
        id: item.flashcard.id,
        term: item.flashcard.term,
        definition: item.flashcard.definition,
      });

      return {
        toReview: toReviewFiltered.map(formatCard),
        learning: learning.map(formatCard),
        newCards,
      };
    } catch (error) {
      console.error("Error fetching flashcards:", error);
    }
  },

  async updateRepetitionStatus({ flashcardId, userId, deckId, option }) {
    const PERFORMANCE_RATINGS = {
      again: 1,
      hard: 2,
      good: 3,
      easy: 4,
    };

    const MAX_INTERVAL = 365; // Define o cap máximo de intervalo como 1 ano

    try {
      let { data: currentStatus, error } = await supabase
        .from("spaced_repetition_status")
        .select("*")
        .eq("flashcard_id", flashcardId)
        .eq("profile_id", userId)
        .not("is_reseted", "is", true)
        .single();

      let newInterval;
      let newEaseFactor = currentStatus ? currentStatus.ease_factor : 2.5;
      let nextReviewDate = new Date();

      if (error && error.code == "PGRST116") {
        newInterval = 1;
        nextReviewDate.setDate(nextReviewDate.getDate() + newInterval);
        await supabase.from("spaced_repetition_status").insert([
          {
            flashcard_id: flashcardId,
            profile_id: userId,
            deck_id: deckId,
            interval: newInterval,
            ease_factor: newEaseFactor,
            next_review_date: nextReviewDate.toISOString(),
            is_reseted: false,
          },
        ]);
      } else if (currentStatus) {
        const { interval: currentInterval } = currentStatus;

        switch (option) {
          case "again":
            newInterval = 1;
            break;
          case "hard":
            newEaseFactor = Math.max(newEaseFactor - 0.2, 1.3);
            newInterval = Math.ceil(currentInterval * newEaseFactor);
            break;
          case "good":
            newInterval = Math.ceil(currentInterval * newEaseFactor);
            break;
          case "easy":
            newEaseFactor += 0.15;
            newInterval = Math.ceil(currentInterval * newEaseFactor * 1.5);
            break;
          default:
            return;
        }

        newInterval = Math.min(Math.max(1, newInterval), MAX_INTERVAL);
        nextReviewDate.setDate(nextReviewDate.getDate() + newInterval);
        await supabase
          .from("spaced_repetition_status")
          .update({
            interval: newInterval,
            ease_factor: newEaseFactor,
            next_review_date: nextReviewDate.toISOString(),
            is_reseted: false,
          })
          .eq("flashcard_id", flashcardId)
          .eq("profile_id", userId);
      }

      await supabase.from("review_history").insert([
        {
          flashcard_id: flashcardId,
          profile_id: userId,
          performance_rating: PERFORMANCE_RATINGS[option],
          review_date: new Date().toISOString(),
          interval: newInterval,
          ease_factor: newEaseFactor,
          deck_id: deckId,
        },
      ]);
    } catch (e) {
      console.log(e);
    }
  },
  async fetchDeckProgress(deckId) {
    try {
      const { count, error: learnedError } = await supabase
        .from("spaced_repetition_status")
        .select("*", { count: "exact" })
        .eq("deck_id", deckId)
        .not("is_reseted", "is", true)
        .gte("ease_factor", 2.5) //'aprendido'
        .limit(1);

      if (learnedError) {
        console.error("Erro ao buscar dados:", learnedError);
        return null;
      }

      return {
        total: await decks.getFlashcardsCountByDeckId(deckId),
        learned: count || 0,
      };
    } catch (e) {
      console.log(e);
    }
  },
  async fetchNewFlashcards(deckId, userId) {
    // Encontrar flashcards no deck que ainda não foram vistos pelo usuário
    try {
      const { data: newFlashcards, error } = await supabase
        .from("flashcards")
        .select("id")
        .eq("deck_id", deckId)
        .not(
          "id",
          "in",
          supabase
            .from("spaced_repetition_status")
            .select("flashcard_id")
            .eq("profile_id", userId)
            .not("is_reseted", "is", true)
        );

      if (error) {
        console.error("Erro ao buscar novos flashcards:", error);
        return [];
      }

      return newFlashcards;
    } catch (e) {
      console.log(e);
    }
  },

  async fetchDifficultFlashcards({ userId, deckId }) {
    // Primeiro, obtenha os IDs dos flashcards difíceis
    try {
      const { data: difficultFlashcards, error } = await supabase
        .from("review_history")
        .select("flashcard_id")
        .in("performance_rating", [1, 2]) // 1 para "again" e 2 para "hard"
        .eq("profile_id", userId)
        .eq("deck_id", deckId);

      if (error) {
        console.error("Erro ao buscar flashcards difíceis:", error);
        return [];
      }

      // Removendo flashcards duplicados
      const uniqueFlashcardIds = [
        ...new Set(difficultFlashcards.map((f) => f.flashcard_id)),
      ];

      // Agora, faça o join com a tabela de flashcards para obter term e definition
      const { data: flashcardDetails, error: detailsError } = await supabase
        .from("flashcards")
        .select("id, term, definition")
        .in("id", uniqueFlashcardIds);

      if (detailsError) {
        console.error("Erro ao buscar detalhes dos flashcards:", detailsError);
        return [];
      }

      return flashcardDetails;
    } catch (e) {
      console.log(e);
    }
  },

  async countFlashcardsByDifficulty({ userId, deckId }) {
    try {
      const { data = [], error } = await supabase
        .from("review_history")
        .select("*, flashcards!inner(id, term, definition)")
        .in("performance_rating", [2, 3, 4]) // 1 para "again" e 2 para "hard"
        .eq("profile_id", userId)
        .eq("deck_id", deckId);

      const result = {
        good: [],
        hard: [],
        easy: [],
      };

      if (error) {
        return;
      }

      data.forEach((item) => {
        switch (item.performance_rating) {
          case 3:
            "good";
            result.good.push(item.flashcards);
            break;
          case 2:
            "hard";
            result.hard.push(item.flashcards);
            break;
          case 4:
            "easy";
            result.easy.push(item.flashcards);
            break;
        }
      });

      return result;
    } catch (e) {
      console.log(e);
    }
  },

  async fetchFlashcardsByDifficulty({ userId, deckId, difficulty }) {
    const ratingMap = { good: 3, hard: 2, easy: 4 };

    try {
      // Obtenha os IDs dos flashcards com a dificuldade especificada
      const { data: flashcardsIds, error } = await supabase
        .from("review_history")
        .select("flashcard_id")
        .eq("performance_rating", ratingMap[difficulty])
        .eq("profile_id", userId)
        .eq("deck_id", deckId);

      if (error) {
        console.error(`Erro ao buscar flashcards ${difficulty}:`, error);
        return [];
      }

      const uniqueIds = [...new Set(flashcardsIds.map((f) => f.flashcard_id))];

      // Busque os detalhes dos flashcards pelos IDs
      const { data: flashcardDetails, error: detailsError } = await supabase
        .from("flashcards")
        .select("id, term, definition")
        .in("id", uniqueIds);

      if (detailsError) {
        console.error("Erro ao buscar detalhes dos flashcards:", detailsError);
        return [];
      }

      return flashcardDetails;
    } catch (e) {
      console.log(e);
    }
  },

  async fetchLearningFlashcards({ userId, deckId }) {
    const learningThreshold = 2.5; // Defina o limiar para considerar um flashcard como "aprendido"

    try {
      const { data: learningFlashcardsIds, error } = await supabase
        .from("spaced_repetition_status")
        .select("flashcard_id")
        .lt("ease_factor", learningThreshold)
        .eq("profile_id", userId)
        .eq("deck_id", deckId)
        .not("is_reseted", "is", true);

      if (error) {
        console.error("Erro ao buscar flashcards em aprendizado:", error);
        return [];
      }

      const uniqueIds = [
        ...new Set(learningFlashcardsIds.map((f) => f.flashcard_id)),
      ];

      // Busque os detalhes dos flashcards pelos IDs
      const { data: flashcardDetails, error: detailsError } = await supabase
        .from("flashcards")
        .select("id, term, definition")
        .in("id", uniqueIds);

      if (detailsError) {
        console.error("Erro ao buscar detalhes dos flashcards:", detailsError);
        return [];
      }

      return flashcardDetails;
    } catch (e) {
      console.log(e);
    }
  },

  async fetchAllFlashcardsForReview({ deckId }) {
    try {
      const { data: allFlashcards, error } = await supabase
        .from("flashcards")
        .select("id, term, definition")
        .eq("deck_id", deckId);

      if (error) {
        console.error(
          "Erro ao buscar todos os flashcards para revisão:",
          error
        );
        return [];
      }

      return allFlashcards;
    } catch (e) {
      console.log(e);
    }
  },

  async countLearnedFlashcards(userId) {
    const learnedThreshold = 2.5; //"aprendido"

    try {
      const { count, error } = await supabase
        .from("spaced_repetition_status")
        .select("*", { count: "exact" })
        .eq("profile_id", userId)
        .gte("ease_factor", learnedThreshold)
        .not("is_reseted", "is", true)
        .limit(1);

      if (error) {
        console.error("Erro ao contar flashcards aprendidos:", error);
        return 0;
      }

      return count;
    } catch (e) {
      console.log(e);
    }
  },

  async calculateTotalStudyTime(userId) {
    const averageTimePerFlashcard = 5; // Tempo médio em segundos

    try {
      const { count, error } = await supabase
        .from("review_history")
        .select("*", { count: "exact" })
        .eq("profile_id", userId)
        .limit(1);

      if (error) {
        console.error("Error fetching review history:", error);
        return "0min";
      }

      const totalSeconds = count * averageTimePerFlashcard;
      const totalMinutes = Math.floor(totalSeconds / 60);

      if (totalMinutes >= 60) {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours}h ${minutes}min`;
      } else {
        return `${totalMinutes}min`;
      }
    } catch (e) {
      console.log(e);
    }
  },

  async downloadDeckByUserId({ userId, deckId }) {
    try {
      const { data, error } = await supabase.from("review_history").insert([
        {
          flashcard_id: null,
          profile_id: userId,
          performance_rating: 0,
          review_date: new Date().toISOString(),
          interval: 0,
          ease_factor: 2.5,
          deck_id: deckId,
        },
      ]);

      if (error) {
        console.error("Erro ao criar review_history fantasma:", error);
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  },

  async resetDeckForUser({ deckId, userId }) {
    try {
      const { error } = await supabase
        .from("spaced_repetition_status")
        .update({ is_reseted: true })
        .match({ deck_id: deckId, profile_id: userId });

      if (error) {
        console.error("Erro ao resetar deck:", error);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async checkIfUserHasExceededFlashcardLimit(userId) {
    try {
      const { data, error } = await supabase
        .rpc("get_flashcards_created_by_date_count", {
          date_param: moment().toISOString(),
          author_param: userId,
        })
        .select("*");

      if (error) {
        console.error("Error fetching flashcard count:", error);
        return true;
      }

      return data >= 10;
    } catch (e) {
      console.log(e);
    }
  },

  async checkIfUserHasExceededPlayLimit(userId) {
    const todayDate = moment().toISOString().split("T")[0];
    const { count } = await supabase
      .from("review_history")
      .select("*", { count: "exact" })
      .eq("profile_id", userId)
      .gte("created_at", todayDate);

    return count;
  },
};

export default decks;
