import { Link } from "gatsby";
import { Text, Button } from "@fluany/ds";
import { AiOutlineWarning } from "react-icons/ai";
import { useState, useEffect } from "react";
import moment from "moment";
import UpgradeButton from "components/UpgradeButton";
import { useUser } from "providers/user";
import "./Expired.scss";

const Expired = ({ isTrialExpired, children }) => {
  if (!isTrialExpired) {
    return null;
  }

  useEffect(() => {
    if (isTrialExpired) {
      document.body.style.paddingBottom = "200px";
    }
  }, [isTrialExpired]);

  return (
    <div className="expired">
      <Text className="expired__banner" size={"md"} weight={700}>
        <AiOutlineWarning size={24} />
        Your trial expired <UpgradeButton isYear size={"small"} />
      </Text>
    </div>
  );
};

export default Expired;
