import * as React from 'react'
import classNames from 'classnames'
import './Text.scss'

type TitleVariant = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

type TextVariant = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type TWeight = 300 | 400 | 800 | 500

type TColor = 'text' | 'dark' | 'primary' | 'secondary' | 'white'

interface ITextProps {
  as?: keyof React.ReactHTML
  size?: TextVariant | TitleVariant
  children?: JSX.Element | string
  className?: string
  weight?: TWeight
  color?: TColor
}

const Text = ({
  size = 'sm',
  children,
  className,
  weight,
  color = 'text',
  ...props
}: ITextProps) => {
  const CustomTagName = props.as || 'p'
  const isTitle = CustomTagName.includes('h')
  const variant = isTitle ? 'title' : 'paragraph'

  delete props.as

  const rootClasses = classNames(
    'ds-text',
    className,
    `ds-text__${variant}`,
    `ds-text__${variant}--${size.toLowerCase()}`,
    `ds-text__${variant}--${color}`
  )

  return (
    <CustomTagName
      className={rootClasses}
      style={{ fontWeight: weight }}
      {...props}
    >
      {children}
    </CustomTagName>
  )
}

export default Text
