const en = {
  id: "en",
  categoryId: 343,
  languageName: "english",
  header: {
    discover: "Explore",
    leaderboard: "Top Students",
    create: "Create",
    ISpeak: "I speak",
    home: "Home",
  },

  explore: {
    title: `Learn Everything, One Flashcard at a Time`,
    description: `Discover the power of flashcards for fast and effective learning. With a wide range of comprehensive topics, our decks are perfect for those seeking practical knowledge and long-lasting memorization. Try it out and see the positive impact on your learning.`,
    lookingFor: `What are you looking to learn?`,
    popularDecks: "Popular Decks",
  },

  learnCategories: {
    english: {
      name: "English",
      title: `Learn English with Flashcards`,
      description: `Explore a simple and effective way to learn English. Our selection of flashcards is perfect for those looking to improve their vocabulary, grammar, and understanding of the language, whether for travel, business, or entertainment. Start today and see the difference in your learning.`,
    },
    medicine: {
      name: "Medicine",
      title: `Boost Your Medical Studies`,
      description: `Discover the method thousands of medical students are using in their daily routine to memorize content from the Basic Cycle to their Medical Residency approval.`,
    },
    entranceExam: {
      name: "Entrance Exam",
      title: `Achieve Success in Entrance Exams`,
      description: `Prepare for your entrance exams with our specialized collection of decks. Here you will find a variety of resources categorized by essential disciplines such as Mathematics, Biology, Chemistry, and more.`,
    },
  },

  pro: {
    title: `Memorize 10x faster with flashcards`,
    description: `Discover the method that thousands of students are using in their daily life, to memorize any content.`,
    items: [
      `Start seeing results in tests`,
      `Better grades and higher performance`,
      `Create your own flashcards`,
      `Chrome extension to force yourself to learn`,
      `Integration with Notion, Anki, and Chat GPT-4.`,
      `Join a global community of flashcard users`,
      `50 thousand decks with audio and videos`,
    ],
    buttonCTA: `Try 7 days free!`,
    month: "month",
    year: "year",
    planMonth: "Monthly",
    planYear: "Annual",
    lifetime: "LIFETIME ACCESS (single payment)",
    total: "total",
    highlight: `50% off on Pro!`,
    cancel: {
      title: `no commitment.`,
      subtitle: `cancel anytime.`,
      description: `We will send reminders about when your free trial will end!`,
    },
    features: {
      title: `2023 the year to invest in yourself`,
      items: [
        {
          title: `Over 50,000 Exclusive Flashcards`,
          description: `Join now and gain access to a vast library of carefully curated flashcards. Expand your knowledge and elevate your learning to new heights with our exclusive content.`,
        },
        {
          title: `Flashcards with Audio`,
          description: `Over 50,000 decks with audio and videos`,
        },
        {
          title: `Profiles`,
          description: `Change your profile picture and add emotions to your day.`,
        },
      ],
    },
    testimonials: {
      title: `Loved by students`,
      description: `Here's what people are saying about us`,
    },
  },

  login: {
    signin: {
      emailLabel: "Your email address",
      passwordLabel: "Your password",
      buttonLabel: "Sign in",
      socialProviderText: "Sign in with",
      linkText: "Already have an account? Sign in",
    },

    signup: {
      emailLabel: "Your email address",
      passwordLabel: "Your password",
      buttonLabel: "Sign up",
      socialProviderText: "Signup with",
      linkText: `Don't have an account? Sign up`,
    },

    forgotten: {
      emailLabel: "Your email address",
      passwordLabel: "Your password",
      buttonLabel: "Sending reset instructions",
      socialProviderText: "Sign in with",
      linkText: "Forgot your password?",
    },

    magicLink: {
      emailLabel: "Your email address",
      buttonLabel: "Send email",
      linkText: "Send a magic link email",
    },
  },

  categories: {
    recent: "Recent",
    clinicalCycle: "Clinical Cycle",
    surgicalClinic: "Surgical Clinic",
    medicalClinic: "Medical Clinic",
    gynecology: "Gynecology",
    pediatrics: "Pediatrics",
    familyHealth: "Family Health",
    cardiology: "Cardiology",
    more: "More",
    beginner: "Beginner",
    intermediate: "Intermediate",
    advanced: "Advanced",
    grammar: "Grammar",
    vocabulary: "Vocabulary",
    travel: "Travel",
    business: "Business",
    moviesAndSeries: "Movies and Series",
    portugueseLanguage: "Portuguese Language",
    mathematics: "Mathematics",
    biology: "Biology",
    chemistry: "Chemistry",
    physics: "Physics",
    history: "History",
    geography: "Geography",
    philosophyAndSociology: "Philosophy and Sociology",
    foreignLanguage: "Foreign Language",
  },

  createDeck: {
    createDeck: "Create Deck",
    name: "Name",
    deckNamePlaceholder: "deck name",
    nameHint: "Naming your deck well will help other learners find it.",
    teaching: "Teaching",
    pleaseSelectOne: "Please select one...",
    for: "For",
    speakers: "speakers",
    description: "Description",
    backgroundImage: "Background Image",
    change: "Change",
    choose: "Choose",
    addIntegration: "Add Integration",
    createDeck: "Create Deck",
    updateDeck: "Update Deck",
    deckPreview: "Deck Preview",
  },

  explorer: {
    deckTabsLabel: "I would like to learn",
  },

  notfound: {
    noFlashcardFound: "No flashcard found",
    wouldYouLikeTo: "Would you like to",
    createOne: "create one",
  },

  whyCreateDeck: {
    whyCreateDeck: "Why create a deck?",
    reason1: "A quick list of personal facts to remember",
    reason2: "Share awesome teaching materials with the community",
    reason3: "Share common content with your classmates/colleagues",
  },

  profile: {
    editProfile: "Edit Profile",
    typeYourName: "Type your name",
    name: "Name",
    username: "Username",
    typeYourUsername: "Type your username",
    picture: "Picture",
    cancel: "Cancel",
    update: "Update",
    deleteMyAccount: "Delete my account",
    streakMessage: `Your streak will reset if you don't practice tomorrow. Watch out!`,
    streakMessage2: "Practice flashcards every day to build your streak",
    learnedMessage: (totalFlashcards) =>
      `Learned ${totalFlashcards} flashcards in the last year`,
    siteLanguage: `Website language`,
  },

  myDecks: {
    owner: "Owner",
    inProgress: "In Progress",
    downloads: "Downloads",
    likes: "Likes",
    exploreNewDecks: "Explore new decks",
    whatDoYouWantToStudy: "What do you want to study?",
  },

  leaderboard: {
    week: "week",
    month: "month",
    overlay: "Practice your flashcards and stay in the top ranking",
  },

  chat: {
    message1:
      "Hello, I'm fluflu! I'm going to help you create your flashcards. 🤓",
    message2: "Tell me, what would you like to practice?",
    buttonSend: "Send",
    iWantMore: "I want more flashcards",
    doYouWantMore: "Do you want more flashcards?",
    saved: "Saved",
    save: "Save",
    placeholder: "Type your message...",
    yes: "Yes",
  },
  play: {
    progress: {
      newCardsTooltip:
        "New Cards: These are cards you haven't seen yet. Complete them to advance in your learning!",
      learningTooltip:
        "Learning: These cards are in the phase of strengthening memory. Review them frequently for better retention.",
      toReviewTooltip:
        "Review: You're reviewing these cards to consolidate knowledge. The more times you get them right, the more spaced the review intervals will become.",
    },

    sessionEnd: {
      title:
        "Congratulations! You've successfully completed your session for now.",
      description:
        "You've made impressive progress today! Remember, revisiting the same session can further solidify your learning. And remember, new learning opportunities await tomorrow! Keep up the momentum for daily growth. See you then!",
    },

    sessionCompleted: {
      title: "Great Progress Achieved!",
      description:
        "Well done! You've covered all the flashcards in this deck. Return later for timely revision sessions to reinforce and retain what you've learned.",
    },

    buttonShowAnswer: "Show Answer",
    labelTerm: "Term",
    labelDefinition: "Definition",

    action: {
      again: "Again",
      hard: "Hard",
      good: "Good",
      easy: "Easy",
    },

    day: "day",
  },

  banner: {
    message: "Enjoy 50% off",
    button: "7 days free",
  },

  deckDetails: {
    lockMessageToPlay: "Subscribe to review this deck.",
    playAgain: "Review again",
    playNow: "Review now",
    upgradeToPlay: `Get Pro and Keep Learning`,
    downloadAndPlay: "Download and review",
    lockMessageToCreate: "Assine to breed flashcards",
    loginAndLearn: `Log In & Learn`,
    createFlashcard: `Create flashcard`,
  },

  upgradeButton: {
    loaderMessage: "Preparing your subscription...",
  },

  widgets: {
    pro: {
      title: `Take Your Studies to the Pro Level`,
      description: `Unlock a world of knowledge with over 50,000 exclusive decks. Become a
          Pro member and transform your learning experience today.`,
      buttonText: `Unlock Full Access`,
    },
  },
};

export default en;
