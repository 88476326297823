import "@fluany/ds/dist/index.css";
import "./src/styles/index.scss";
import Root from "./src/Root";
import config from "config";

import * as Sentry from "@sentry/gatsby";

if (!config.isDev) {
  Sentry.init({
    dsn: "https://382bc0d85e594d04bd96d4e01d22058c@o4505544986132480.ingest.sentry.io/4505545139552256",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const wrapRootElement = Root;
