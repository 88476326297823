import { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../services/auth";
import services from "services";
import { loadStripe } from "@stripe/stripe-js";
import posthog from "posthog-js";
import { navigate } from "gatsby";
import Expired from "components/Expired";
import moment from "moment";
import config from "config";
import utils from "utils";
import UpgradeNow from "components/UpgradeNow";
import WelcomeModal from "components/Welcome";

const isProd = process.env.NODE_ENV !== "development";

export const UserContext = createContext({});

function getUsername(emailAddress) {
  return emailAddress.match(/^(.+)@/)[1];
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    avatar_url: "https://avatars.dicebear.com/api/big-smile/.svg",
  });
  const [isLoading, setLoading] = useState(true);
  const [isModalUpgradeOpen, setIsModalUpgradeOpen] = useState(false);
  const [isModalWelcomeOpen, setIsModalWelcome] = useState(false);
  const [isFluanyExtensionActived, setIsFluanyExtensionActived] =
    useState(true);
  const [upgradeModalProps, setUpgradeModalProps] = useState({});
  const [langISpeak, setLangISpeak] = useState({
    label: "English (US)",
    value: 343,
  });

  const isPro = user?.subscription_tier === "PRO";
  const dateCreated = moment(user?.created_at);
  const dateExpiration = dateCreated.add(8, "days");
  const isTrialExpired = isPro ? false : moment().isAfter(dateExpiration);

  const openUpgrade = (props) => {
    setIsModalUpgradeOpen(true);
    setUpgradeModalProps(props);
  };

  const changeUser = async (newUser) => {
    try {
      if (newUser) {
        const { data: profile, error } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", newUser?.id)
          .single();

        if (error) throw error;

        setUser({
          ...newUser,
          ...profile,
        });
      }
    } catch (error) {
      console.error("Error changing user: ", error);
    }
  };

  useEffect(() => {
    async function updateUsername() {
      try {
        const usernameFromEmail = getUsername(user?.email);
        const username = await services.user.generateUsername(
          usernameFromEmail
        );
        await services.user.changeUsername(user, username);

        setUser({
          ...user,
          username,
        });
      } catch (error) {
        console.error("Error updating username: ", error);
      }
    }

    async function updateAvatarUrl() {
      try {
        const newAvatarUrl = await services.user.generateAvatarUrl(user?.id);
        setUser({
          ...user,
          avatar_url: newAvatarUrl,
        });
      } catch (error) {
        console.error("Error updating avatar URL: ", error);
      }
    }

    if (user?.id) {
      if (user?.email && isProd) {
        posthog.identify(user?.id, {
          email: user?.email,
          name: user?.full_name,
        });
      }

      if (!user?.username) {
        updateUsername();
      }

      if (!user?.avatar_url) {
        updateAvatarUrl();
      }
    }
  }, [user]);

  useEffect(() => {
    try {
      const showModalWelcome = !localStorage.getItem("@fluany/welcome");
      const isFirstTime = !sessionStorage.getItem("@fluany/second-time");

      if (user?.id) {
        localStorage.setItem("f_u", user?.id);

        if (utils.isRunningInReactNativeWebView()) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              user: user,
            })
          );
        }

        if (isFirstTime && !isPro) {
          sessionStorage.setItem("@fluany/second-time", true);
        }

        if (showModalWelcome && user?.id) {
          setIsModalWelcome(true);
          localStorage.setItem("@fluany/welcome", true);
        }
      }
    } catch (error) {
      console.error("Error in welcome modal: ", error);
    }
  }, [user?.id]);

  useEffect(() => {
    try {
      const lang = localStorage.getItem("@fluany/user-lang");
      if (lang) {
        setLangISpeak(JSON.parse(lang));
      }
    } catch (error) {
      console.error("Error loading user language: ", error);
    }
  }, []);

  useEffect(() => {
    try {
      localStorage.setItem("@fluany/user-lang", JSON.stringify(langISpeak));
    } catch (error) {
      console.error("Error saving user language: ", error);
    }
  }, [langISpeak]);

  useEffect(() => {
    (async function () {
      try {
        const extensionActived = localStorage.getItem(
          "@fluany-extension/active"
        );
        if (extensionActived) {
          setIsFluanyExtensionActived(true);
        }

        try {
          const {
            data: { session },
            error,
          } = await supabase.auth.getSession();
          if (error) throw error;

          if (session?.user && !user?.id) {
            const { data: profile, error } = await supabase
              .from("profiles")
              .select("*")
              .eq("id", session?.user?.id)
              .single();

            if (error) throw error;

            setUser({
              ...session?.user,
              ...profile,
            });
          }

          setLoading(false);
        } catch (error) {
          console.error("Error in auth state change: ", error);
        }

        try {
          const { data: listener } = supabase.auth.onAuthStateChange(
            async (event, session) => {
              if (session?.user && !user?.id) {
                const { data: profile, error } = await supabase
                  .from("profiles")
                  .select("*")
                  .eq("id", session?.user?.id)
                  .single();

                if (error) throw error;

                setUser({
                  ...user,
                  ...session?.user,
                  ...profile,
                });
              }

              setLoading(false);
            }
          );
        } catch (error) {
          console.error("Error during session handling: ", error);
        }
      } catch (error) {
        console.error("Error in auth state change: ", error);
      }
    })();
  }, []);

  const logout = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      localStorage.clear();
      window.location.href = "/login";
    } catch (error) {
      console.error("Error during logout: ", error);
    }
  };

  const onUpgrade = async ({
    planId = "price_1OAqy7Jh6ehZGkX0YuV6qMoG",
  } = {}) => {
    try {
      if (!user?.id) {
        navigate("/login/");
        return;
      }

      const { data, error } = await supabase.functions.invoke(
        "create-stripe-checkout",
        {
          body: JSON.stringify({
            planId,
            appName: "fluany",
          }),
        }
      );

      if (error) throw error;

      const stripe = await loadStripe(process.env.GATSBY_STRIPE_KEY);
      await stripe?.redirectToCheckout({ sessionId: data?.id });
    } catch (error) {
      console.error("Error during upgrade: ", error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        isUserLoading: isLoading,
        user,
        isPro,
        changeUser,
        logout,
        setUser,
        openUpgrade,
        setLangISpeak,
        onUpgrade,
        langISpeak,
        isTrialExpired,
        isFluanyExtensionActived,
      }}
    >
      <>
        <UpgradeNow
          isOpen={isModalUpgradeOpen}
          setIsOpen={setIsModalUpgradeOpen}
          {...upgradeModalProps}
        />

        <WelcomeModal
          isOpen={isModalWelcomeOpen}
          setIsOpen={setIsModalWelcome}
        />
        {children}
      </>
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  return context;
};
