import { supabase } from "./auth";

const uid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const uploadAsset = async (file, fileName, fileType) => {
  try {
    const session = await supabase.auth.getSession();
    const userId = session?.data?.session?.user?.id;
    const path = `${userId}/${uid()}-${fileName}`;

    const { data, error } = await supabase.storage
      .from("deck_assets")
      .upload(path, file, {
        cacheControl: "3600",
        upsert: false,
      });

    return `https://dykagtuaezkefvvmskzb.supabase.co/storage/v1/object/public/deck_assets/${data?.path}`;
  } catch (e) {
    console.log(e);
  }
};

const uploadProfileAsset = async (file, fileName, fileType) => {
  try {
    const session = await supabase.auth.getSession();
    const userId = session?.data?.session?.user?.id;
    const path = `${userId}/${uid()}-${fileName}`;

    const { data, error } = await supabase.storage
      .from("avatars")
      .upload(path, file, {
        cacheControl: "3600",
        upsert: false,
      });

    return `https://dykagtuaezkefvvmskzb.supabase.co/storage/v1/object/public/avatars/${data?.path}`;
  } catch (e) {
    console.log(e);
  }
};

export default {
  uploadAsset,
  uploadProfileAsset,
};
