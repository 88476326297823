import { supabase } from "./auth";

export const itemsMock = [
  {
    full_name: "Lucas Oliveira",
    total_know_flashcards: 932,
    avatar_url: `/persons/p-1.png`,
    username: "kenju",
  },
  {
    full_name: "Ketlyn Souza",
    total_know_flashcards: 892,
    avatar_url: `/persons/p-3.png`,
    username: "ketlynsouz",
  },

  {
    full_name: "Marcos",
    total_know_flashcards: 822,
    avatar_url: `/persons/p-2.png`,
    username: "harold",
  },

  {
    full_name: "James",
    total_know_flashcards: 792,
    avatar_url: `/persons/p-9.png`,
    username: "james",
  },

  {
    full_name: "Michael",
    total_know_flashcards: 698,
    avatar_url: `/persons/p-5.png`,
    username: "zmichael",
  },
  {
    full_name: "Marcos",
    total_know_flashcards: 695,
    avatar_url: `/persons/p-6.png`,
    username: "100richard",
  },

  {
    full_name: "Jesse",
    total_know_flashcards: 615,
    avatar_url: `/persons/p-4.png`,
    username: "jesse2020",
  },

  {
    full_name: "Natanael",
    total_know_flashcards: 544,
    avatar_url: `/persons/p-8.png`,
    username: "jesse2020",
  },

  {
    full_name: "Kamila",
    total_know_flashcards: 512,
    username: "jesse2020",
    avatar_url: `/persons/p-7.png`,
  },

  {
    full_name: "Vinicius",
    total_know_flashcards: 312,
    avatar_url: `/persons/p-10.png`,
    username: "jesse2020",
  },
];

export default {
  async getAll(filterName, limit = 10) {
    // const hasCache = sessionStorage.getItem(
    //   `@fluany/leaderboard-${filterName}`
    // );

    // const leaders = hasCache ? JSON.parse(hasCache) : null;

    // if (leaders && limit == 10) {
    //   return leaders;
    // }

    let { data = [], error } = await supabase.rpc("get_leaderboard", {
      time_period: filterName,
    });

    const result = data.map((item) => ({
      username: item.username,
      full_name: item.profile_name,
      avatar_url: item.profile_avatar_url,
      total_know_flashcards: item.flashcards_reviewed,
    }));

    if (error) {
      console.error("Error fetching leaderboard:", error);
      return [];
    }

    // if (result?.length > 0) {
    //   sessionStorage.setItem(
    //     `@fluany/leaderboard-${filterName}`,
    //     JSON.stringify(result)
    //   );
    // }

    return [...itemsMock, result];
  },
};
