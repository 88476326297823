import { navigate } from "gatsby";
import Select from "components/Select";
import { Tabs, Button, Text } from "@fluany/ds";
import { useDeck } from "providers/deck";
import { useEffect, useState } from "react";
import { useUser } from "providers/user";
import { useTranslation } from "providers/language";
import "./ISpeak.scss";

const DEFAULT_OPTION = {
  label: "English (US)",
  value: 343,
};

const ISpeak = () => {
  const { categories } = useDeck();
  const { language, switchLanguage } = useTranslation();

  const iSpeakOptions = [
    {
      label: "English",
      value: "en",
      image: "https://hatscripts.github.io/circle-flags/flags/us.svg",
    },

    {
      label: "Português",
      value: "pt",
      image: "https://hatscripts.github.io/circle-flags/flags/br.svg",
    },

    {
      label: "Español",
      value: "es",
      image: "https://hatscripts.github.io/circle-flags/flags/es.svg",
    },
  ];

  const speakValue = iSpeakOptions?.find((i) => i.value === language?.id);

  return (
    <div className="ispeak">
      {/* <Text weight={700} size={"xss"}> */}
      {/*   {language.header.ISpeak} */}
      {/* </Text> */}

      <Select
        placeholder="Select one..."
        options={iSpeakOptions}
        value={speakValue}
        formatOptionLabel={(country) => (
          <div className="country-option">
            <img src={country.image} alt="country-image" />
            <span>{country.label}</span>
          </div>
        )}
        onChange={(option) => {
          switchLanguage(option.value);
        }}
      />
    </div>
  );
};

export default ISpeak;
