import config from "config";
import { supabase } from "services/auth";

const baseURL = config.isDev
  ? `https://fluany-433111662801.us-east1.run.app`
  : `https://fluany-433111662801.us-east1.run.app`;

const sendChat = async ({
  messages,
  onResponse,
  onDone,
  firstMessage,
  lang = "en",
}) => {
  try {
    const session = await supabase.auth.getSession();
    const payload = {
      messages,
      lang,
      firstMessage,
    };

    return new Promise(async (resolve) => {
      const response = await fetch(
        `${baseURL}/chat/`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            authorization: session?.data?.session?.access_token,
            "user-id": session?.data?.session?.user?.id,
          },
        },
        []
      );

      const reader = response.body.getReader();

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          onDone && onDone();
          resolve();
          break;
        }

        const chunkText = new TextDecoder().decode(value);
        onResponse(chunkText);
      }

      return response;
    });
  } catch (e) {
    console.log(e);
  }
};

const getAudio = async ({ text }) => {
  const cacheKey = `audioCache_${text}`;

  const cachedAudio = localStorage.getItem(cacheKey);
  if (cachedAudio) {
    const audioContent = atob(cachedAudio);
    const audioArrayBuffer = new Uint8Array(audioContent.length);

    for (let i = 0; i < audioContent.length; i++) {
      audioArrayBuffer[i] = audioContent.charCodeAt(i);
    }
    return audioArrayBuffer;
  }

  try {
    const session = await supabase.auth.getSession();

    const response = await fetch(`${baseURL}/text-to-speech/`, {
      method: "POST",
      body: JSON.stringify({ text }),
      headers: {
        "Content-Type": "application/json",
        authorization: session?.data?.session?.access_token,
        "user-id": session?.data?.session?.user?.id,
      },
    })
      .then((r) => r.json())
      .then((r) => r.response?.data);

    const audioBase64 = btoa(
      String.fromCharCode.apply(null, new Uint8Array(response))
    );
    localStorage.setItem(cacheKey, audioBase64);

    return response;
  } catch (e) {
    console.log(e);
  }
};

const playAudio = async ({ text: textHtml }) => {
  try {
    const session = await supabase.auth.getSession();

    const text = textHtml
      .replace(/(<([^>]+)>)/gi, "")
      ?.replaceAll("&nbsp;", "");

    const result = await getAudio({
      text,
    });

    const arr = Uint8Array.from(result);
    const audioContext = new AudioContext();

    const audio = await audioContext.decodeAudioData(arr.buffer);
    const source = audioContext.createBufferSource();
    source.buffer = audio;
    source.connect(audioContext.destination);
    source.start(0);
  } catch (e) {
    console.log(e);
  }
};

export default {
  sendChat,
  getAudio,
  playAudio,
};
