import React, { useState, useEffect } from "react";
import { Button, Text } from "@fluany/ds";
import ISpeak from "components/Header/components/ISpeak";
import { useTranslation } from "providers/language";
import "./LanguageModal.scss";

const LanguageModal = () => {
  const { language, switchLanguage, setCountry, langsAvailable } =
    useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const loadLanguageByServer = () => {
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        setCountry(data);
        localStorage.setItem("country", JSON.stringify(data));

        const lang = data.languages.split(",")[0].split("-")[0];
        if (langsAvailable[lang]) {
          switchLanguage(langsAvailable[lang]);
        } else {
          switchLanguage("en");
        }
      })
      .catch((error) => {
        console.error("There was an issue determining the language:", error);
      });
  };

  useEffect(() => {
    const language = localStorage.getItem("language");
    if (!language) {
      setIsModalVisible(true);
      loadLanguageByServer();
    }
  }, []);

  const handleLanguageChange = (e) => {
    const language = e.target.value;
    setSelectedLanguage(language);
    localStorage.setItem("language", language);
    setIsModalVisible(false);
  };

  return isModalVisible ? (
    <div className="language-modal__overlay">
      <div className="language-modal">
        <Text as={"h2"}>Escolha sua língua nativa</Text>
        <ISpeak />
        <Button onClick={() => setIsModalVisible(false)} weight={700} fluid>
          Confirm
        </Button>
      </div>
    </div>
  ) : null;
};

export default LanguageModal;
