import React from 'react'

import './Input.scss'

interface InputProps {
  className: string
  validationMessage: any
}

const Input = ({ className, validationMessage, ...props }: InputProps) => {
  return (
    <div className='ds-input'>
      <input
        title={validationMessage}
        autoComplete='off'
        className={`ds-input__field ${className}`}
        {...props}
      />
      {validationMessage && (
        <span className='ds-input__validation-message'>
          {validationMessage}
        </span>
      )}
    </div>
  )
}

export default Input
