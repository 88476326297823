const es = {
  id: "es",
  categoryId: 383,
  languageName: "spanish",

  header: {
    discover: "Explorar",
    leaderboard: "Estudiantes Destacados",
    create: "Crear",
    ISpeak: "Hablo",
    home: "Principal",
  },

  explore: {
    title: `Aprende de Todo, Un Flashcard a la Vez`,
    description: `Descubre el poder de los flashcards para un aprendizaje rápido y eficaz. Con una amplia variedad de temas completos, nuestros mazos son perfectos para quienes buscan conocimiento práctico y memorización duradera. Pruébalo y observa el impacto positivo en tu aprendizaje.`,
    lookingFor: `¿Qué estás buscando aprender?`,
    popularDecks: "Decks Populares",
  },

  learnCategories: {
    english: {
      name: "Inglés",
      title: `Aprende Inglés con Flashcards`,
      description: `Explora una manera simple y efectiva de aprender inglés. Nuestra selección de flashcards es perfecta para aquellos que quieren mejorar su vocabulario, gramática y comprensión del idioma, ya sea para viajes, negocios o entretenimiento. Comienza hoy y observa la diferencia en tu aprendizaje.`,
    },
    medicine: {
      name: "Medicina",
      title: `Impulsa tus estudios de Medicina`,
      description: `Conoce el método que miles de estudiantes de medicina están utilizando en su día a día para memorizar contenido desde el Ciclo Básico hasta su aprobación en la Residencia Médica.`,
    },
    entranceExam: {
      name: "Examen de admisión",
      title: `Conquista el Éxito en los Exámenes de Ingreso`,
      description: `Prepárate para tus exámenes de ingreso con nuestra colección especializada de mazos. Aquí encontrarás una variedad de recursos categorizados por disciplinas esenciales como Matemáticas, Biología, Química y más.`,
    },
  },

  pro: {
    title: `Memoriza 10 veces más rápido con tarjetas didácticas`,
    description: `Descubre el método que miles de estudiantes están utilizando en su vida diaria, para memorizar cualquier contenido.`,
    items: [
      `Empieza a ver resultados en exámenes`,
      `Mejores calificaciones y mayor rendimiento`,
      `Crea tus propias tarjetas didácticas`,
      `Extensión de Chrome para obligarte a aprender`,
      `Integración con Notion, Anki y Chat GPT-4.`,
      `Únete a una comunidad global de usuarios de tarjetas didácticas`,
      `50 mil mazos con audio y vídeos`,
    ],
    buttonCTA: `¡Prueba 7 días gratis!`,
    month: "mes",
    year: "año",
    planMonth: "Mensual",
    planYear: "Anual",
    lifetime: "ACCESO DE POR VIDA (pago único)",
    total: "total",
    highlight: `¡50% de descuento en Pro!`,
    cancel: {
      title: `sin compromiso.`,
      subtitle: `cancela en cualquier momento.`,
      description: `¡Te enviaremos recordatorios sobre cuándo terminará tu prueba gratuita!`,
    },
    features: {
      title: `2023 el año de invertir en ti mismo`,
      items: [
        {
          title: `Más de 50 Mil Flashcards Exclusivos`,
          description: `Únete ahora y accede a una amplia biblioteca de flashcards cuidadosamente seleccionados. Amplía tu conocimiento y lleva tu aprendizaje a nuevos niveles con nuestro contenido exclusivo.`,
        },
        {
          title: `Flashcards con Audios`,
          description: `Más de 50 mil mazos con audio y vídeos`,
        },
        {
          title: `Perfiles`,
          description: `Cambia la foto de tu perfil y añade emociones a tu día.`,
        },
      ],
    },
    testimonials: {
      title: `Amado por los estudiantes`,
      description: `Aquí está lo que la gente dice sobre nosotros`,
    },
  },

  login: {
    signin: {
      emailLabel: "Tu dirección de correo electrónico",
      passwordLabel: "Tu contraseña",
      buttonLabel: "Iniciar sesión",
      socialProviderText: "Iniciar sesión con",
      linkText: "¿Ya tienes una cuenta? Iniciar sesión",
    },

    signup: {
      emailLabel: "Tu dirección de correo electrónico",
      passwordLabel: "Tu contraseña",
      buttonLabel: "Registrarse",
      socialProviderText: "Registrarse con",
      linkText: "¿No tienes una cuenta? Registrarse",
    },

    forgotten: {
      emailLabel: "Tu dirección de correo electrónico",
      passwordLabel: "Tu contraseña",
      buttonLabel: "Enviar instrucciones de restablecimiento",
      socialProviderText: "Iniciar sesión con",
      linkText: "¿Olvidaste tu contraseña?",
    },

    magicLink: {
      emailLabel: "Tu dirección de correo electrónico",
      buttonLabel: "Enviar correo",
      linkText: "Enviar un correo con enlace mágico",
    },
  },

  categories: {
    recent: "Recientes",
    clinicalCycle: "Ciclo Clínico",
    surgicalClinic: "Clínica Quirúrgica",
    medicalClinic: "Clínica Médica",
    gynecology: "Ginecología",
    pediatrics: "Pediatría",
    familyHealth: "Salud Familiar",
    cardiology: "Cardiología",
    more: "Más",
    beginner: "Principiante",
    intermediate: "Intermedio",
    advanced: "Avanzado",
    grammar: "Gramática",
    vocabulary: "Vocabulario",
    travel: "Viaje",
    business: "Negocio",
    moviesAndSeries: "Películas y Series",

    portugueseLanguage: "Lengua Portuguesa",
    mathematics: "Matemáticas",
    biology: "Biología",
    chemistry: "Química",
    physics: "Física",
    history: "Historia",
    geography: "Geografía",
    philosophyAndSociology: "Filosofía y Sociología",
    foreignLanguage: "Lengua Extranjera",
  },
  createDeck: {
    createDeck: "Crear Mazo",
    name: "Nombre",
    deckNamePlaceholder: "nombre del mazo",
    nameHint:
      "Darle un buen nombre a tu mazo ayudará a otros aprendices a encontrarlo.",
    teaching: "Enseñando",
    pleaseSelectOne: "Por favor seleccione uno...",
    for: "Para",
    speakers: "hablantes",
    description: "Descripción",
    backgroundImage: "Imagen de Fondo",
    change: "Cambiar",
    choose: "Elegir",
    addIntegration: "Agregar Integración",
    createDeck: "Crear Mazo",
    updateDeck: "Actualizar Mazo",
    deckPreview: "Vista Previa del Mazo",
  },
  explorer: {
    deckTabsLabel: "Me gustaría aprender",
  },
  notfound: {
    noFlashcardFound: "Ninguna tarjeta didáctica encontrada",
    wouldYouLikeTo: "Te gustaría",
    createOne: "crear una",
  },
  whyCreateDeck: {
    whyCreateDeck: "¿Por qué crear un mazo?",
    reason1: "Una lista rápida de datos personales para recordar",
    reason2: "Compartir materiales didácticos impresionantes con la comunidad",
    reason3: "Compartir contenido común con tus compañeros de clase/colegas",
  },
  profile: {
    editProfile: "Editar Perfil",
    typeYourName: "Escribe tu nombre",
    typeYourUsername: "Escribe tu nombre de usuario",
    name: "Nombre",
    username: "Usuario",
    picture: "Foto",
    cancel: "Cancelar",
    update: "Actualizar",
    deleteMyAccount: "Eliminar mi cuenta",
    streakMessage: "Tu racha se reiniciará si no practicas mañana. ¡Cuidado!",
    streakMessage2:
      "Practica tarjetas didácticas todos los días para construir tu racha",
    learnedMessage: (totalFlashcards) =>
      `Aprendido ${totalFlashcards} tarjetas didácticas en el último año`,
    siteLanguage: "Idioma del sitio",
  },
  myDecks: {
    owner: "Propietario",
    inProgress: "En Progreso",
    downloads: "Descargas",
    likes: "Me gusta",
    exploreNewDecks: "Explorar nuevos mazos",
    whatDoYouWantToStudy: "¿Qué quieres estudiar?",
  },

  leaderboard: {
    week: "semana",
    month: "mes",
    overlay:
      "Practica tus tarjetas didácticas y mantente en el ranking superior",
  },

  chat: {
    message1:
      "¡Hola, soy fluflu! Voy a ayudarte a crear tus tarjetas didácticas. 🤓",
    message2: "Dime, ¿sobre qué te gustaría practicar?",
    buttonSend: "Enviar",
    iWantMore: "Quiero más tarjetas didácticas",
    doYouWantMore: "¿Quieres más tarjetas didácticas?",
    saved: "Guardado",
    save: "Guardar",
    placeholder: "Escribe tu mensaje...",
    yes: "Sí",
  },
  play: {
    progress: {
      newCardsTooltip:
        "Nuevas Tarjetas: Son las tarjetas que aún no has visto. ¡Complétalas para avanzar en tu aprendizaje!",
      learningTooltip:
        "Aprendiendo: Estas tarjetas están en fase de fortalecimiento de la memoria. Revísalas con frecuencia para una mejor retención.",
      toReviewTooltip:
        "Revisión: Estás repasando estas tarjetas para consolidar el conocimiento. Cuantas más veces aciertes, más espaciados serán los intervalos de revisión.",
    },

    sessionEnd: {
      title: "¡Felicidades! Has completado con éxito tu sesión por ahora.",
      description:
        "¡Hiciste un progreso impresionante hoy! Recuerda, revisitar la misma sesión puede reforzar aún más tu aprendizaje. Y recuerda, ¡nuevas oportunidades de aprendizaje te esperan mañana! Mantén el impulso para el crecimiento diario. ¡Hasta entonces!",
    },

    sessionCompleted: {
      title: "¡Gran Progreso Alcanzado!",
      description:
        "¡Bien hecho! Has cubierto todas las tarjetas educativas de este mazo. Vuelve más tarde para sesiones de repaso oportunas para reforzar y retener lo que has aprendido.",
    },

    buttonShowAnswer: "Mostrar Respuesta",
    labelTerm: "Término",
    labelDefinition: "Definición",

    action: {
      again: "De nuevo",
      hard: "Difícil",
      good: "Bien",
      easy: "Fácil",
    },

    day: "día",
  },

  banner: {
    message: "Aprovecha el descuento del 50%",
    button: "7 días gratis",
  },

  deckDetails: {
    lockMessageToPlay: "Suscríbete para revisar este mazo.",
    playAgain: "Revisar de nuevo",
    playNow: "Revisar ahora",
    upgradeToPlay: `Hazte Pro y Sigue Aprendiendo`,
    downloadAndPlay: "Descargar y revisar",
    lockMessageToCreate: "Suscríbete para crear tarjetas didácticas",
    loginAndLearn: `Inicia Sesión & Aprende`,
    createFlashcard: `Crear flashcard`,
  },

  upgradeButton: {
    loaderMessage: "Preparando tu suscripción...",
  },

  widgets: {
    pro: {
      title: `Lleva Tus Estudios al Nivel Pro`,
      description: `Desbloquea un mundo de conocimiento con más de 50,000 mazos exclusivos. Conviértete en
        un miembro Pro y transforma tu experiencia de aprendizaje hoy mismo.`,
      buttonText: `Desbloquea Acceso Completo`,
    },
  },
};
export default es;
