import React, { useEffect } from 'react'
import Text from '../Text'
import { MdOutlineClose } from 'react-icons/md'
import './Modal.scss'

interface ModalProps {
  children: any
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  title: string
  width: string | number
  id: string
}

const Modal = ({
  width,
  id = '',
  children,
  isOpen,
  setIsOpen,
  title
}: ModalProps) => {
  const handleEsc = (event: any) => {
    if (event.keyCode === 27) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keyup', handleEsc)
    }

    return () => {
      window.removeEventListener('keyup', handleEsc)
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  return (
    <div>
      <div className='ds-modal__overlay' onClick={() => setIsOpen(false)} />
      <div className='ds-modal' style={{ width }} id={id}>
        <header
          className='ds-modal__header'
          style={{
            justifyContent: title ? 'space-between' : 'flex-end'
          }}
        >
          {title && <Text as='h3'>{title}</Text>}
          <span className='ds-modal__close' onClick={() => setIsOpen(false)}>
            <MdOutlineClose size={25} />
          </span>
        </header>
        <div className='ds-modal__body'>{children}</div>
      </div>
    </div>
  )
}

export default Modal
