import decks from "./decks";
import user from "./user";
import habit from "./habit";
import stripe from "./stripe";
import leaderboard from "./leaderboard";
import integrations from "./integrations";
import courses from "./courses";
import ai from "./ai";
import storage from "./storage";

export default {
  leaderboard,
  integrations,
  stripe,
  habit,
  decks,
  user,
  courses,
  ai,
  storage,
};
