import React, { useState } from "react";
import { Text, Button, Modal } from "@fluany/ds";
import { ReactComponent as ExploreIcon } from "./explore.svg";
import { FullScreenLoader } from "components/Loader";
import { supabase } from "services/auth";
import { loadStripe } from "@stripe/stripe-js";
import {
  MdInvertColors,
  MdVolumeUp,
  MdQueryStats,
  MdStar,
  MdCheck,
} from "react-icons/md";

import "./UpgradeNow.scss";

const prices = [
  {
    discount: 19,
    price: 9,
    isSubscription: true,
    items: ["Cancel anytime", "Access to all features"],
    buttonText: "$9/month",
    buttonType: "terciary",
    border: "1px solid var(--color-gray2)",
    planId: "price_1OFZtiJh6ehZGkX0gS3uEw99",
  },
  {
    discount: 49,
    isSubscription: false,
    price: 29,
    items: ["One-time payment forever", "Access all features"],
    buttonText: "Forever",
    buttonType: "gold",
    border: "1px solid #f8a800",

    planId: "price_1P5jskJh6ehZGkX0jz4ldAlV",
  },
];

const Testimonial = ({ text }) => {
  return (
    <div className="testimonial">
      <div className="testimonial__stars">
        <MdStar size={25} />
        <MdStar size={25} />
        <MdStar size={25} />
        <MdStar size={25} />
        <MdStar size={22} />
      </div>

      <Text
        size={"xs"}
        color={"dark"}
        className="testimonial__text"
        weight={700}
      >
        "{`${text}`}"
      </Text>
    </div>
  );
};

const UpgradeNow = ({ isOpen, setIsOpen, message, title }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubscription = async (planId, isSubscription) => {
    setIsLoading(true);
    const { data, error } = await supabase.functions.invoke(
      "create-stripe-checkout",
      {
        body: JSON.stringify({
          planId,
          isSubscription,
        }),
      }
    );

    if (error) {
      console.error(error);
      return;
    }

    const stripe = await loadStripe(process.env.GATSBY_STRIPE_KEY);
    await stripe?.redirectToCheckout({ sessionId: data?.id });
  };

  return (
    <div className="upgradenow">
      <Modal id={"upgrade"} width={560} isOpen={isOpen} setIsOpen={setIsOpen}>
        {message && <Text className="upgradenow__message">{message}</Text>}
        <Text as={"h2"} size={"sm"} className="upgradenow__title">
          {title || "Upgrade and continue to Study"}
        </Text>

        <Text className="upgradenow__description" size={"xs"}>
          Unlock all the features and take your learning to the next level
        </Text>

        <div className="upgradenow__panel">
          <img style={{ maxWidth: 154 }} src={"/illustrations/girl-flu.svg"} />
          <div className="upgradenow__testimonial">
            <Testimonial text="Real progress happens in a few minutes!" />
            <Testimonial text="Best app for leaning new languages" />
          </div>
        </div>

        <Button
          as={"a"}
          href={
            "https://fluany.com/?utm_source=fluany-app&utm_medium=upgrade-modal&utm_campaign=upgrade-modal"
          }
          type={"gold"}
          size={"small"}
          style={{ width: "100%", marginTop: 20 }}
        >
          Get my access
        </Button>
      </Modal>
    </div>
  );
};

export default UpgradeNow;
