import { supabase } from "./auth";

const getDatesForLast7Days = () => {
  const dates = [];
  for (let i = 7; i >= 0; i--) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(date.toISOString().split("T")[0]); // Adiciona no final do array
  }
  return dates;
};

export default {
  async getHabitDataFlashcardCountAndStreaks(userId) {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    try {
      let { data: reviewData, error } = await supabase
        .from("review_history")
        .select("review_date, flashcard_id")
        .eq("profile_id", userId)
        .gte("review_date", oneYearAgo.toISOString());

      if (error) {
        console.error("Error fetching review history:", error);
        return {
          heatmapData: [],
          flashcardCount: 0,
          currentStreak: 0,
          longestStreak: 0,
        };
      }

      const uniqueFlashcardIds = new Set();
      const dateCounts = {};
      let currentStreak = 0,
        longestStreak = 0,
        prevDate = "";

      reviewData.forEach(({ review_date, flashcard_id }) => {
        const date = new Date(review_date).toISOString().split("T")[0];
        dateCounts[date] = (dateCounts[date] || 0) + 1;
        uniqueFlashcardIds.add(flashcard_id);
      });

      const sortedDates = Object.keys(dateCounts).sort();
      sortedDates.forEach((date, index) => {
        if (index > 0) {
          const diff = new Date(date) - new Date(sortedDates[index - 1]);
          if (diff === 86400000) {
            currentStreak++;
          } else if (diff > 86400000) {
            longestStreak = Math.max(longestStreak, currentStreak);
            currentStreak = 1;
          }
        } else {
          currentStreak = 1;
        }
        longestStreak = Math.max(longestStreak, currentStreak); // Atualiza o longestStreak a cada iteração
      });

      // Verifica se o streak atual inclui o dia de ontem
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const yesterdayString = yesterday.toISOString().split("T")[0];
      if (!dateCounts[yesterdayString]) {
        currentStreak = 0;
      }

      const heatmapData = sortedDates.map((date) => {
        return { date, count: dateCounts[date] };
      });

      return {
        heatmapData,
        flashcardCount: uniqueFlashcardIds.size,
        currentStreak,
        longestStreak,
      };
    } catch (e) {
      console.log(e);
    }
  },
};
