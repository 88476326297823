import { supabase } from "./auth";
import serviceDecks from "./decks";

const generateUsername = async function (name) {
  try {
    let username = name;
    let isUsername;
    let number = 0;
    let exist = await User.checkIfExistUsername(username);
    while (exist) {
      number++;
      const newUsername = `${name}${number}`;
      const newExist = await User.checkIfExistUsername(newUsername);

      if (!newExist) {
        exist = false;
        username = newUsername;
      } else {
        exist = true;
      }
    }

    return username;
  } catch (e) {
    console.log(e);
  }
};

const User = {
  generateUsername,
  async checkIfExistUsername(username) {
    try {
      const result = await supabase
        .from("profiles")
        .select("*", { count: "exact", head: true })
        .eq("username", username);

      return !!result.count;
    } catch (e) {
      console.log(e);
    }
  },

  async changeUsername(user, username) {
    try {
      await supabase.from("profiles").update({ username }).eq("id", user?.id);
    } catch (e) {
      console.log(e);
    }
  },

  async changeFullname(user, fullName) {
    try {
      await supabase
        .from("profiles")
        .update({ full_name: fullName })
        .eq("id", user?.id);
    } catch (e) {
      console.log(e);
    }
  },

  async getByUsername(username) {
    try {
      const { data } = await supabase
        .from("profiles")
        .select("*")
        .eq("username", username)
        .single();

      return data;
    } catch (e) {
      console.log(e);
    }
  },

  async setAvatarColor(newColor, userId) {
    try {
      const { data } = await supabase
        .from("profiles")
        .update({ avatar_color: newColor })
        .eq("id", userId);

      return data;
    } catch (e) {
      console.log(e);
    }
  },

  async generateAvatarUrl(userId) {
    const avatarUrl = `https://avatars.dicebear.com/api/identicon/${Math.random()}.svg`;

    try {
      const { data } = await supabase
        .from("profiles")
        .update({ avatar_url: avatarUrl })
        .eq("id", userId);

      return avatarUrl;
    } catch (e) {
      console.log(e);
    }
  },

  async setAvatarEmoji(newEmoji, userId) {
    try {
      const { data } = await supabase
        .from("profiles")
        .update({ avatar_emoji: newEmoji })
        .eq("id", userId);

      return data;
    } catch (e) {
      console.log(e);
    }
  },

  async setAvatarUrl(newAvatarUrl, userId) {
    try {
      const { data } = await supabase
        .from("profiles")
        .update({ avatar_url: newAvatarUrl })
        .eq("id", userId);

      return data;
    } catch (e) {
      console.log(e);
    }
  },

  async deleteAccount(userId) {
    try {
      await supabase
        .from("profiles_decks_likes")
        .delete()
        .eq("profile_id", userId);

      await supabase.from("profiles_decks").delete().eq("profile_id", userId);

      await supabase
        .from("flashcards_progress")
        .delete()
        .eq("profile_id", userId);

      await supabase
        .from("flashcards_know_history")
        .delete()
        .eq("profile_id", userId);

      const { data: allDecks } = await supabase
        .from("profiles_decks")
        .select("*")
        .eq("profile_id", userId);

      for (const deck of allDecks) {
        await supabase.from("flashcards").delete().eq("deck_id", deck.id);
      }

      await supabase.from("decks_tags").delete().eq("profile_id", userId);
      await supabase.from("decks").delete().eq("author", userId);

      //delete my courses
      const { data: allCourses } = await supabase
        .from("courses")
        .select("*")
        .eq("author", userId);

      for (const course of allCourses) {
        await supabase
          .from("courses_decks")
          .delete()
          .eq("course_id", course.id);
        await supabase
          .from("levels_courses")
          .delete()
          .eq("course_id", course.id);
      }

      await supabase.from("courses").delete().eq("author", userId);
      await supabase.from("profiles").delete().eq("id", userId);
    } catch (e) {
      console.log(e);
    }
  },
};

export default User;
