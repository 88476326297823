import { supabase } from "./auth";
import utils from "../utils";

const colors = [
  "#96d1ec",
  "rgba(238, 129, 195, 56%)",
  "rgba(100, 199, 110, 56%)",
  "rgba(255, 176, 171, 56%)",
];

const coursesData = [
  {
    id: 1,
    name: "English for Beginners",
    description:
      "Learn how to have a natural conversation FAST from a native English Speaker.",
    color: colors[0],
    img: "bag-blue.png",
  },

  {
    id: 2,
    name: "Learning of Programming",
    description:
      "Complete grammar course to finish studying english grammar + improve conversation.",
    color: colors[1],
    img: "bag-pink.png",
  },

  {
    id: 3,
    name: "Vestibular Preparation",
    description:
      "Complete grammar course to finish studying english grammar + improve conversation.",
    color: colors[2],
    img: "math-green.png",
  },

  {
    id: 4,
    name: "Business Comunication",
    description:
      "Complete grammar course to finish studying english grammar + improve conversation.",
    color: colors[3],
    img: "programming-orange.png",
  },

  {
    id: 5,
    name: "Basic Mathematics",
    description:
      "Complete grammar course to finish studying english grammar + improve conversation.",
    color: colors[2],
    img: "math-green.png",
  },

  {
    id: 6,
    name: "English for Beginners",
    description:
      "Learn how to have a natural conversation FAST from a native English Speaker.",
    color: colors[0],
    img: "bag-blue.png",
  },

  {
    id: 7,
    name: "Business Comunication",
    description:
      "Complete grammar course to finish studying english grammar + improve conversation.",
    color: colors[3],
    img: "programming-orange.png",
  },

  {
    id: 8,
    name: "Learning of Programming",
    description:
      "Complete grammar course to finish studying english grammar + improve conversation.",
    color: colors[1],
    img: "bag-pink.png",
  },
];

export default {
  async getAll({ source, target }) {
    try {
      const { data } = await supabase
        .from("courses")
        .select(
          `
id,
name,
description,
author(username, avatar_url),
img
`
        )
        .eq("source", source)
        .eq("target", target);
      // return [];
      return data || [];
    } catch (e) {
      console.log(e);
    }
  },

  async getById(id) {
    const hasCache = sessionStorage?.getItem(`@fluany/courses-${id}`);

    if (hasCache) {
      const data = JSON.parse(hasCache);
      return data;
    }

    const { data } = await supabase
      .from("courses_decks")
      .select(
        `
   courses: course_id!inner(
     name,
     description,
     img,
     author(username, avatar_url),
     target,
     source
   ),
   order,
   decks: deck_id!inner(
     profiles: author!inner(username),
     name,
     description,
     id
   ),
   levels_course: level!inner(
     name,
     order
   )
`
      )
      .eq("courses.id", id);

    const newData =
      data?.map((item) => ({
        ...item.decks,
        order: item.order,
        courseName: item.courses.name,
        courseDescription: item.courses.description,
        courseImg: item.courses.img,
        courseAuthor: item.courses.author,
        courseTarget: item.courses.target,
        courseSource: item.courses.source,
        level_name: item.levels_course.name,
        level: {
          order: item.levels_course.order,
          name: item.levels_course.name,
        },
      })) || {};

    const grouped = utils.groupBy(newData, "level_name");

    const levels = Object.keys(grouped).map((levelName) => {
      return {
        decks: grouped[levelName],
        name: levelName,
        order: grouped[levelName][0]?.level?.order,
      };
    });

    const levelsOrdered = levels?.sort((a, b) => a.order - b.order);

    const firstDeck = levelsOrdered?.[0]?.decks?.[0];

    const details = {
      name: levelsOrdered?.[0]?.decks?.[0]?.courseName,
      description: levelsOrdered?.[0]?.decks?.[0]?.courseDescription,
      img: levelsOrdered?.[0]?.decks?.[0]?.courseImg,
      author: levelsOrdered?.[0]?.decks?.[0]?.courseAuthor,
      levels: levelsOrdered,
      target: levelsOrdered?.[0]?.decks?.[0]?.courseTarget,
      source: levelsOrdered?.[0]?.decks?.[0]?.courseSource,
    };

    sessionStorage.setItem(
      `@fluany/courses-${id}`,
      JSON.stringify({
        currentDeck: firstDeck,
        details,
      })
    );

    return {
      details,
      currentDeck: firstDeck,
    };
  },

  async create(course, levels) {
    const { author, target, source, name, description, img } = course;

    const { data } = await supabase.from("courses").insert(course).select("id");

    const courseId = data?.[0]?.id;
    for (let level of levels) {
      if (level?.name) {
        const { data: levelData } = await supabase
          .from("levels_courses")
          .insert({
            name: level?.name,
            course_id: courseId,
          })
          .select("id");

        const levelId = levelData?.[0]?.id;

        for (let deck of level.decks) {
          if (deck?.value) {
            await supabase.from("courses_decks").insert({
              deck_id: deck?.value,
              course_id: courseId,
              level: levelId,
            });
          }
        }
      }
    }

    return courseId;
  },

  async deleteById(courseId) {
    await supabase.from("courses_decks").delete().eq("course_id", courseId);

    await supabase.from("levels_courses").delete().eq("course_id", courseId);

    await supabase.from("courses").delete().eq("id", courseId);
  },

  async getAllCoursesByUserId(userId) {
    const { data } = await supabase
      .from("courses")
      .select(`*`)
      .eq("author", userId);
    return data;
  },

  async update(course, levels) {
    const { id: courseId, ...newCourse } = course;
    await supabase.from("courses").update(newCourse).eq("id", courseId);

    //delete before to insert again
    await supabase.from("courses_decks").delete().eq("course_id", courseId);
    await supabase.from("levels_courses").delete().eq("course_id", courseId);

    for (let level of levels) {
      if (level?.name) {
        const { data: levelData } = await supabase
          .from("levels_courses")
          .insert({
            name: level?.name,
            course_id: courseId,
          })
          .select("id");

        const levelId = levelData?.[0]?.id;

        for (let deck of level.decks) {
          if (deck?.value) {
            await supabase.from("courses_decks").insert({
              deck_id: deck?.value,
              course_id: courseId,
              level: levelId,
            });
          }
        }
      }
    }

    sessionStorage?.removeItem(`@fluany/courses-${courseId}`);

    return courseId;
  },
};
