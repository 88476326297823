// pt.js
const pt = {
  id: "pt",
  categoryId: 375,
  languageName: "portuguese",

  header: {
    discover: "Explorar",
    leaderboard: "Top Estudantes",
    create: "Criar",
    ISpeak: "Eu falo",
    home: "Início",
  },

  explore: {
    title: `Aprenda de Tudo, Um Flashcard de Cada Vez`,
    description: `Descubra o poder dos flashcards para aprender de forma rápida e eficaz. Com uma variedade de tópicos abrangentes, nossos decks são perfeitos para quem busca conhecimento prático e memorização duradoura. Experimente e veja o impacto positivo no seu aprendizado.`,
    lookingFor: `O que você está procurando aprender?`,
    popularDecks: "Decks populares",
  },

  learnCategories: {
    english: {
      name: "Inglês",
      title: `Aprenda Inglês com flashcards`,
      description: `Explore uma maneira simples e eficaz de aprender inglês. Nossa seleção de flashcards é perfeita para quem quer melhorar o vocabulário, a gramática e a compreensão do idioma, seja para viagens, negócios ou entretenimento. Comece hoje e veja a diferença no seu aprendizado.`,
    },

    medicine: {
      name: "Medicina",
      title: `Turbine seus estudos em Medicina`,
      description: `Conheça o método que milhares de estudantes de medicina estão usando no seu dia a dia, para memorizar conteúdo do Ciclo Básico, até sua aprovação na Residência Médica.`,
    },

    entranceExam: {
      name: "Vestibular",
      title: `Conquiste o Sucesso no Vestibular`,
      description: `Prepare-se para o vestibular com nossa coleção especializada de decks. Aqui você encontrará uma variedade de recursos categorizados por disciplinas essenciais como Matemática, Biologia, Química, e mais.`,
    },
  },

  pro: {
    title: `Memorize 10x mais rápido com flashcards`,
    description: `Conheça o método que milhares de estudantes estão usando no seu dia a dia, para memorizar qualquer conteúdo.`,
    items: [
      `Começa a ver resultados em provas`,
      `Notas melhores e rendimento maior`,
      `Crie seus próprios flashcards`,
      `Extensão do Chrome para se forçar a aprender`,
      `Integração com Notion, Anki e Chat GPT-4.`,
      `Junte-se a uma comunidade global de flashcards`,
      `50 mil decks com áudio e vídeos`,
    ],
    buttonCTA: `Experimente 7 dias grátis!`,
    month: "mês",
    year: "ano",
    planMonth: "Mensal",
    planYear: "Anual",
    lifetime: "ACESSO VITALÍCIO (único pagamento)",
    total: "total",
    highlight: `50% off no Pro!`,
    cancel: {
      title: `sem compromisso.`,
      subtitle: `cancele a qualquer momento.`,
      description: `Enviaremos lembretes sobre quando seu teste gratuito terminará!`,
    },
    features: {
      title: `2023 o ano de investir em você`,
      items: [
        {
          title: `Mais de 50 Mil Flashcards Exclusivos`,
          description: `Junte-se agora e tenha acesso a uma vasta biblioteca de flashcards cuidadosamente selecionados. Amplie seu conhecimento e leve seu aprendizado a novos patamares com nosso conteúdo exclusivo.`,
        },
        {
          title: `Flashcards com áudios`,
          description: `+50 mil decks com áudio e vídeos`,
        },

        {
          title: `Perfils`,
          description: `Mude a foto do seu perfil e adicione emoções ao seu dia.`,
        },
        ,
      ],
    },
    testimonials: {
      title: `Amado pelos estudantes`,
      description: `Aqui está o que as pessoas estão dizendo sobre nós`,
    },
  },

  login: {
    signin: {
      emailLabel: "Seu endereço de email",
      passwordLabel: "Sua senha",
      buttonLabel: "Entrar",
      socialProviderText: "Entrar com",
      linkText: "Já tem uma conta? Entrar",
    },

    signup: {
      emailLabel: "Seu endereço de email",
      passwordLabel: "Sua senha",
      buttonLabel: "Cadastre-se",
      socialProviderText: "Cadastre-se com",
      linkText: "Não tem uma conta? Cadastre-se",
    },

    forgotten: {
      emailLabel: "Seu endereço de email",
      passwordLabel: "Sua senha",
      buttonLabel: "Enviar instruções de reset",
      socialProviderText: "Entrar com",
      linkText: "Esqueceu sua senha?",
    },

    magicLink: {
      emailLabel: "Seu endereço de email",
      buttonLabel: "Enviar email",
      linkText: "Enviar um email com link mágico",
    },
  },

  categories: {
    recent: "Recentes",
    clinicalCycle: "Ciclo Clínico",
    surgicalClinic: "Clínica Cirúrgica",
    medicalClinic: "Clínica Médica",
    gynecology: "Ginecologia",
    pediatrics: "Pediatria",
    familyHealth: "Saúde da Família",
    cardiology: "Cardiologia",
    more: "Mais",

    beginner: "Iniciante",
    intermediate: "Intermediário",
    advanced: "Avançado",
    grammar: "Gramática",
    vocabulary: "Vocabulário",
    travel: "Viagem",
    business: "Negócio",
    moviesAndSeries: "Filmes e Séries",

    portugueseLanguage: "Língua Portuguesa",
    mathematics: "Matemática",
    biology: "Biologia",
    chemistry: "Química",
    physics: "Física",
    history: "História",
    geography: "Geografia",
    philosophyAndSociology: "Filosofia e Sociologia",
    foreignLanguage: "Língua Estrangeira",
  },
  createDeck: {
    createDeck: "Criar Baralho",
    name: "Nome",
    deckNamePlaceholder: "nome do baralho",
    nameHint:
      "Dar um bom nome ao seu baralho ajudará outros aprendizes a encontrá-lo.",
    teaching: "Ensinando",
    pleaseSelectOne: "Por favor, selecione um...",
    for: "Para",
    speakers: "falantes",
    description: "Descrição",
    backgroundImage: "Imagem de Fundo",
    change: "Alterar",
    choose: "Escolher",
    addIntegration: "Adicionar Integração",
    createDeck: "Criar Baralho",
    updateDeck: "Atualizar Baralho",
    deckPreview: "Pré-visualização do Baralho",
  },
  explorer: {
    deckTabsLabel: "Eu gostaria de aprender",
  },
  notfound: {
    noFlashcardFound: "Nenhum flashcard encontrado",
    wouldYouLikeTo: "Você gostaria de",
    createOne: "criar um",
  },
  whyCreateDeck: {
    whyCreateDeck: "Por que criar um baralho?",
    reason1: "Uma lista rápida de informações pessoais para lembrar",
    reason2: "Compartilhar material didático incrível com a comunidade",
    reason3:
      "Compartilhar conteúdo comum com seus colegas de classe/colaboradores",
  },
  profile: {
    editProfile: "Editar Perfil",
    typeYourName: "Digite seu nome",
    name: "Nome",
    username: "Nickname",
    typeYourUsername: "Digite seu nome de usuário",
    picture: "Foto",
    cancel: "Cancelar",
    update: "Atualizar",
    deleteMyAccount: "Excluir minha conta",
    streakMessage:
      "Sua sequência será reiniciada se você não praticar amanhã. Cuidado!",
    streakMessage2:
      "Pratique flashcards todos os dias para construir sua sequência",
    learnedMessage: (totalFlashcards) =>
      `Aprendeu ${totalFlashcards} flashcards no último ano`,
    siteLanguage: `Idioma do site`,
  },
  myDecks: {
    owner: "Proprietário",
    inProgress: "Em Andamento",
    downloads: "Downloads",
    likes: "Curtidas",
    exploreNewDecks: "Explorar novos baralhos",
    whatDoYouWantToStudy: "O que você quer estudar?",
  },

  leaderboard: {
    week: "semana",
    month: "mês",
    overlay: "Pratique seus flashcards e mantenha-se no topo do ranking",
  },

  chat: {
    message1:
      "Olá, eu sou o fluflu! Vou te ajudar a montar seus flashcards. 🤓",
    message2: "Me diga, sobre o que você deseja praticar?",
    buttonSend: "Enviar",
    iWantMore: `eu quero mais flashcards`,
    doYouWantMore: `Quer mais flashcards?`,
    saved: `Salvo`,
    save: `Salvar`,
    placeholder: `Digite sua mensagem...`,
    yes: `Sim`,
  },

  play: {
    progress: {
      newCardsTooltip:
        "Novos Cartões: Estes são os cartões que você ainda não viu. Conclua-os para avançar na sua aprendizagem!",
      learningTooltip:
        "Aprendendo: Estes cartões estão em fase de fortalecimento da memória. Revise-os com frequência para melhor retenção.",
      toReviewTooltip:
        "Revisão: Você está revisando estes cartões para consolidar o conhecimento. Quanto mais vezes acertar, mais espaçados serão os intervalos de revisão.",
    },

    sessionEnd: {
      title: "Parabéns! Você concluiu com sucesso a sua sessão por agora.",
      description:
        "Você fez um progresso impressionante hoje! Lembre-se, revisitar a mesma sessão pode fortalecer ainda mais o seu aprendizado. E lembre-se, novas oportunidades de aprendizado esperam por você amanhã! Mantenha o ritmo para o crescimento diário. Te vejo em breve!",
    },

    sessionCompleted: {
      title: "Grande Progresso Alcançado!",
      description:
        "Parabéns! Você revisou todos os flashcards deste baralho. Retorne mais tarde para sessões de revisão oportunas para reforçar e reter o que aprendeu.",
    },

    buttonShowAnswer: "Mostrar resposta",
    labelTerm: "Termo",
    labelDefinition: "Definição",

    action: {
      again: "Novamente",
      hard: "Esqueci",
      good: "Lembrei",
      easy: "Fácil",
    },
    day: "dia",
  },

  banner: {
    message: "Aproveite o desconto de 50%",
    button: "7 dias grátis",
  },

  deckDetails: {
    lockMessageToPlay: "Assine para revisar este deck.",
    playAgain: "Revisar novamente",
    playNow: "Revisar agora",
    upgradeToPlay: `Torne-se Pro e Continue Aprendendo`,
    downloadAndPlay: "Fazer download e revisar",
    lockMessageToCreate: "Assine para criar flashcards",
    loginAndLearn: `Faça Login & Aprenda`,
    createFlashcard: `Criar flashcard`,
  },

  upgradeButton: {
    loaderMessage: "Preparando sua assinatura...",
  },

  widgets: {
    pro: {
      title: `Eleve Seu Estudo ao Nível Pro`,
      description: `Desbloqueie um mundo de conhecimento com mais de 50.000 decks exclusivos. Torne-se um
        membro Pro e transforme sua experiência de aprendizado hoje mesmo.`,
      buttonText: `Desbloqueie Acesso Completo`,
    },
  },
};

export default pt;
