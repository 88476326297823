import CustomSelect from "react-select";
import "./Select.scss";

const Select = ({ children, options = [], noBackground, ...props }) => {
  const customBorder = { border: 0 };

  const customStyle = {
    menu: (defaultStyles, state) => ({
      ...defaultStyles,
      backgroundColor: "var(--color-container-bg)",
      zIndex: 999,
    }),
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#FFF" : "var(--color-text)",
    }),
    singleValue: (defaultStyles, state) => ({
      ...defaultStyles,
      color: noBackground ? "#FFF" : "var(--color-text)",
    }),
    control: (base, state) => ({
      ...base,
      ...customBorder,
      padding: 0,
      transition: "none",
      borderColor: state.isFocused
        ? "var(--color-primary)"
        : "var(--color-gray2)",
      borderRadius: 20,
      backgroundColor: noBackground
        ? "transparent"
        : "var(--color-container-bg)",
      boxShadow: "none",
      "&:hover": {
        borderColor: "var(--color-primary)",
      },
      color: "#FFF",
    }),
  };

  return (
    <div className="ds-select">
      <CustomSelect
        {...props}
        styles={customStyle}
        options={options}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            text: "var(--color-text)",
            primary: "var(--color-primary)",
            primary25: "var(--color-gray2)",
            primary50: "var(--color-gray2)",
            primary75: "var(--color-gray2)",
            neutral50: "var(--color-text)",
            neutral90: "var(--color-text)",
            neutral: "var(--color-text)",
          },
        })}
      />
    </div>
  );
};

export default Select;
