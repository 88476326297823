import React from 'react'
import classnames from 'classnames'
import './Button.scss'

interface ButtonProps {
  children: any
  type: 'primary' | 'secondary' | 'terciary' | 'default' | 'link' | 'gold'
  size: 'small' | 'medium' | 'large'
  className: string
  as: any
  to: string
  ghost: boolean
  attrs: any
  isDisabled: boolean
  removePadding: boolean
  align: 'center' | 'right'
  fluid: boolean
  weight: number
}

const Button = ({
  children,
  type = 'primary',
  size = 'medium',
  className = '',
  attrs = {},
  as: As = '',
  to = '',
  ghost = false,
  removePadding = false,
  isDisabled = false,
  align,
  fluid,
  weight = 400,
  ...props
}: ButtonProps) => {
  const classes = classnames('ds-button', className, {
    'ds-button--default': type === 'default',
    'ds-button--gold': type === 'gold',
    'ds-button--primary': type === 'primary',
    'ds-button--link': type === 'link',
    'ds-button--secondary': type === 'secondary',
    'ds-button--terciary': type === 'terciary',
    'ds-button--small': size === 'small',
    'ds-button--medium': size === 'medium',
    'ds-button--large': size === 'large',
    'ds-button--icon': typeof children === 'object' && children?.length > 1,
    'ds-button--ghost': ghost,
    'ds-button--disabled': isDisabled,
    'ds-button--nopadding': removePadding,
    'ds-button--align-center': align === 'center',
    'ds-button--align-right': align === 'right',
    'ds-button--fluid': fluid
  })

  if (As && to) {
    return (
      <As className={classes} to={to} {...props}>
        {children}
      </As>
    )
  } else if (As) {
    return (
      <As className={classes} {...props}>
        {children}
      </As>
    )
  }

  return (
    <button
      className={classes}
      {...attrs}
      style={{ fontWeight: weight }}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
