import React, { useState, useEffect } from "react";
import { Text } from "@fluany/ds";
import "./Loader.scss";

import { ReactComponent as Icon } from "./icon.svg";
const Loader = ({ text, center, props, width, height }) => {
  const style = center ? { textAlign: "center", display: "flex" } : {};
  return (
    <div className="loader" style={style} {...props}>
      <Icon style={{ width, height }} />
      {text && <Text size={"xs"}>{text}</Text>}
    </div>
  );
};

const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 37 39"
    {...props}
  >
    <path
      fill="#FF7272"
      d="M26.395 27.552a13.163 13.163 0 0 1-.764-.774c1.68 3.226 1.198 7.604-.784 9.794-.5.552-1.415.128-1.315-.61.055-.409.099-.806.132-1.192.17-1.978.06-3.642-.289-4.94-.686-2.56-2.295-3.692-4.492-2.989a.51.51 0 0 0-.033.01l-.017.006a3.023 3.023 0 0 0 .06.608c.186.95.79 1.864 1.527 2.768.402.489.75 1.02 1.04 1.583 1.208 2.371 1.064 4.206.316 5.39a3.288 3.288 0 0 1-1.83 1.4c-.468.142-.877-.346-.672-.791l.02-.046c.805-1.785.375-3.885-1.008-5.27-1.51-1.513-3.137-2.65-3.525-5.686a10.534 10.534 0 0 1-.062-.67c-2.178 5.05-.136 8.273.37 11.803a.678.678 0 0 1-.018.289.692.692 0 0 1-1.084.35c-3.027-2.3-4.129-6.189-2.961-11.951l.035-.174c-1.766 2.34-2.217 6.256-2.207 10.726v.101c.003.727-.873 1.107-1.395.602-2.556-2.471-2.006-8.394.541-12.382-.087.04-.174.079-.259.12-3.07 1.45-4.585 3.78-4.368 7.086.049.75.247 1.488.622 2.139.138.242.23.508.272.784.16 1.02-.995 1.718-1.834 1.116a5.036 5.036 0 0 1-.352-.277C-.638 34.18-.666 29.558 1.858 25.73c2.318-3.33 2.764-5.653 1.895-8.59a12.635 12.635 0 0 1-.74-4.275C3.01 5.76 8.886 0 16.133 0c.888 0 1.773.087 2.644.261 5.981 1.2 10.479 6.386 10.479 12.603a12.608 12.608 0 0 1-.809 4.456c-.075.184-.148.363-.217.539l-.006.012c-.554 1.412-.895 2.592-1.016 3.576-.206 1.686.823 3.241 2.34 3.748a2.82 2.82 0 0 0 .283.08c.27.066.547.097.825.093 2.443-.026 4.198-2.646 3.203-6.095a3.013 3.013 0 0 1 .006-.969c.108-.598.741-.867 1.234-.661a.877.877 0 0 1 .378.301 6.688 6.688 0 0 1 1.001 2.102h.001c1.6 5.698-3.79 13.348-10.085 7.506Z"
    />
    <path
      id="left-arm"
      fill="#1A1A1A"
      d="M8.141 24.892a2.11 2.11 0 0 1-.936.994c-2.723 1.462-4.057 3.71-3.852 6.826.048.75.246 1.488.622 2.14.138.242.23.508.271.784.16 1.02-.994 1.717-1.833 1.115a5.033 5.033 0 0 1-.352-.276c1.13.056 1.063-.485.578-1.862-.503-1.43-.503-3.997-.503-3.997.08-1.503.473-2.815 1.17-3.944.591-.957 1.476-1.69 2.493-2.171.73-.345 1.355-.847 1.79-1.637a.347.347 0 0 1 .599-.015c.361.71.22 1.446-.047 2.043Z"
      opacity={0.25}
    />
    <path
      fill="#1A1A1A"
      d="M29.549 25.195c-1.844-.536-3.501-1.26-4.718-2.425-1.102-1.056-1.208-2.771-.302-3.999 5.08-6.878.633-17.055-8.396-18.771l2.643.261h.001c5.98 1.2 10.478 6.386 10.478 12.603a12.61 12.61 0 0 1-.808 4.456c-.076.184-.148.363-.217.539l-.006.012c-.554 1.412-.895 2.592-1.016 3.576-.205 1.686.824 3.24 2.34 3.748Z"
      opacity={0.3}
    />
    <path
      fill="#1A1A1A"
      d="m25.88 27.047-1.914-3.028c3.451 5.141 12.15 5.918 12.51-3.972h.001c1.644 5.85-4.087 13.762-10.596 7Z"
      opacity={0.25}
    />
    <path
      fill="#1A1A1A"
      d="M24.847 36.57c-.5.552-1.415.128-1.315-.61.055-.41.099-.807.132-1.193 1.724-2.792 2.575-6.02-.59-11.236-.078-.13.09-.266.2-.163l.694.649 2.426 3.532a13.161 13.161 0 0 1-.764-.773c1.68 3.226 1.199 7.603-.783 9.793ZM21.776 37.21a15.275 15.275 0 0 1-.001-.794c.053-2.559-.813-3.824-1.396-4.384a11.166 11.166 0 0 1-1.63-1.933c-1.945-2.974-2.139-5.17-.37-6.497.442-.332 1.044.12.852.638-.384.733-.41 1.903-.38 2.615.012.337.038.57.042.608v.005c.186.95.79 1.864 1.526 2.767.402.49.751 1.02 1.041 1.584 1.208 2.371 1.064 4.206.316 5.39ZM15.07 37.95a.678.678 0 0 1-.019.287 3.668 3.668 0 0 1-.215-.234c-1.825-2.145-2.287-5.902-2.287-5.902-.121-3.483.35-6.12 1.72-7.467.3-.295.797.007.672.409l-.074.235-.105 1.538a10.451 10.451 0 0 1-.063-.67c-2.177 5.05-.135 8.273.37 11.803ZM13.376 23.508l-.83 1.004c-.645.59-1.185 1.46-1.541 2.121l.035-.174c-1.766 2.34-2.217 6.256-2.207 10.726l-.125-.33c-1.363-3.699-.304-7.378-.304-7.378 1.1-3.376 2.588-5.72 4.722-6.348.224-.066.399.198.25.379Z"
      opacity={0.25}
    />
    <path
      fill="#fff"
      d="M12.305 10.618c-1.297-.147-2.487 1.132-2.66 2.857-.172 1.724.74 3.242 2.037 3.39 1.296.147 2.487-1.132 2.66-2.857.171-1.724-.74-3.242-2.037-3.39Z"
    />
    <path
      fill="#1A1A1A"
      d="M11.462 11.439c-.438-.004-.807.483-.823 1.086-.016.604.327 1.096.766 1.1.439.003.807-.483.823-1.087.017-.604-.327-1.096-.766-1.1Z"
    />
    <path
      fill="#fff"
      d="M20.783 10.608c1.301-.107 2.452 1.207 2.572 2.936.12 1.73-.838 3.218-2.139 3.326-1.3.108-2.452-1.206-2.572-2.936-.12-1.729.839-3.218 2.14-3.325Z"
    />
    <path
      fill="#1A1A1A"
      d="M20.177 11.442c.437.038.758.558.716 1.16-.042.603-.43 1.06-.868 1.022-.437-.039-.757-.559-.715-1.161.041-.603.43-1.06.867-1.021Z"
    />
  </svg>
);

export const FullScreenLoader = () => {
  const messages = [
    "Great choice! Preparing your secure checkout... 💳",
    "Almost ready! Setting up your payment details for Stripe... 🔍",
    "Just a moment, we're finalizing your secure session... ✅",
    "All set! Redirecting you to a secure payment environment on Stripe... 🔐",
    "Hang tight, ensuring everything is perfect for your checkout... 🌟",
    "Thank you for your patience. We're about to redirect you for payment... 👍",
  ];

  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (messageIndex < messages.length - 1) {
        setMessageIndex((prevIndex) => prevIndex + 1);
      }
    }, 5000);

    setCurrentMessage(messages[messageIndex]);

    return () => clearInterval(interval);
  }, [messageIndex, messages]);

  return (
    <div className="fullscreen-loader">
      <div className="fullscreen-loader__content">
        <p className="fullscreen-loader__text">{currentMessage}</p>
      </div>
    </div>
  );
};

export default Loader;
