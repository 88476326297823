export const categories = {
  medicine: [392, 393, 394, 395, 396, 397, 398],
  english: [400, 401, 402, 403, 404],
  entranceExam: [405, 406, 407, 408, 409, 410, 411, 412, 413, 311],
};

export const medicalIds = [392, 393, 394, 395, 396, 397, 398];

export const englishLevels = {
  "en-beginner": [530, 70, 590, 599, 606, 613, 44, 46, 47],
  "en-intermediate": [595, 594, 607, 602, 605, 593, 666, 60],
  "en-advanced": [66, 51, 558, 56, 526, 548, 609, 610, 667, 546],
};
