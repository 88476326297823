import AppLayout from "layouts/App";
import { UserProvider } from "./providers/user";
import { LanguageProvider } from "./providers/language";
import { DeckProvider } from "./providers/deck";

function wrapElement({ element }) {
  return (
    <LanguageProvider>
      <AppLayout>
        <UserProvider>
          <DeckProvider>{element}</DeckProvider>
        </UserProvider>
      </AppLayout>
    </LanguageProvider>
  );
}

export default wrapElement;
